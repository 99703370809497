import React, {Component, useEffect, useState } from 'react'


import AccordionItem from './AccordionItem';





const data = [
  {
    title: 'Why should I buy this?',
    paragraph:'BAYC is one of the few blue chip NFT projects and has paved the way for the space. Displaying an ape as your pfp is instantly recognizable, garners respect, admiration and attention. In only a few months of existence, some notable members of the Yacht Club include super star athletes like Stephen curry and artists like Post Malone. The Forever Ape NFT and accompanying coffee book documents the important moments of the BAYC community and the story of its members, all of which contributed to this unprecedented rise in 2021.'

  },
  /*{
    title: 'Who can mint?',
    
    paragraph:'There are 2 NFTs that will be available: Genesis and Rise.<br>'+
     'The Genesis NFT is reserved for Ape owners who have contributed their story to Forever Apes. Genesis is capped at 150 - each represents one story in the coffee table book. Story contributors will mint the Genesis NFT for at no cost, and at a later date, redeem the Forever Apes Coffee Table book for free.<br></br>'+
     'The Rise NFT is reserved for the wider community: BAYC, MAYC, and BAKC holders. There will be 5000 Rise Tokens will be available to mint at 0.07 ETH. Rise NFT holders can redeem the Forever Apes Coffee Table book at cost + shipping. Cost is TBD Q1 2022.'
  },
  {
    title: 'Who can redeem a physical coffee table book?',
    paragraph:'After the Genesis and Rise token mint, we will announce the date for redeeming your coffee table book. Wallets holding a Genesis or Rise token at the time of the snapshot will be eligible to redeem.'
  },
  */
  {
    title: 'What’s in the book?',
paragraph:'The Forever Apes Coffee Table Book is a first of a kind experience exclusively for the ape community. It features the personal stories of notable BAYC community members, highlighting how the BAYC has changed the lives of so many. It also includes historic and important moments from the rapidly evolving NFT space - taking the reader on a journey to explain why JPEGs are worth hundreds of thousands of dollars today.<br></br>'+
'The physical book is meant to be judged by its cover: the luxurious grey and black ape silhouette instantly garners attention and intrigue. All pages are printed using the highest quality printing techniques on heavy stock paper making it as great on the inside as it is on the outside.'
  },
  {
    title: 'What do I get for contributing my story?',
paragraph:'OG BAYC members will get the opportunity to submit their story. Your ape, story, and twitter/discord handles will be featured as one of 150 original ape stories.<br></br>'+
'As a thank you for your contribution, you will receive a Genesis NFT at no cost, which can be used to redeem your copy of the Forever Apes Coffee Table book for free.'
  },/*
  {
    title: 'What do I get for contributing my story?',
paragraph:'As an approved story contributor, your Ape, story, twitter/discord handles will be presented in the book in high quality. In addition, you will be able to get the following absolutely FREE<br>'+
"  a. Genesis Token<br>"+
"  b. Forever Apes Coffee Table Book"

  },
  {
    title: 'Wen mint?',
paragraph:'Chapter 1 is currently ongoing. Our team is currently collecting and curating 150 stories from OG Ape owners. Once this is chapter is complete, minting for Genesis and Rise Token will begin.'
  },
  {
    title: 'How much will the NFTs cost?',
paragraph:'GENESIS NFTs will be available to mint for free for story contributors. GENESIS token holders can redeem the Forever Apes Coffee Table book for Free.<br>'+
"5000 RISE Tokens will be available to the public at 0.07. RISE token holders can redeem the Forever Apes Coffee Table book at cost + shipping. Cost of book is TBD Q1 2022."
  },
  {
    title: 'What else do I get as a Rise token holder?',

    paragraph:'After the Forever Ape Coffee Table, we have plans to curate stories for our second book featuring Mutants. As a Rise holder, you’ll be given a mint pass that lets you redeem MAYC Coffee Table book at a discount.'
  },*/
  {
    title: 'I’m a genesis holder and I sold my ape. What happens to my story in the book?',
paragraph:'While Forever Apes is about finding your ape that you’ll never part with, we understand that circumstances in life can change - and sometimes that means parting way with your ape. As an OG BAYC holder, your ape and story will live on in the Forever Apes Coffee Table book.'
  },
  ,
  {
    title: 'Other roadmap plans?',
paragraph:'While the first few chapters in our story starts with a coffee table book, we’re committed for the long term to provide apes exclusive experiences and a platform for any ape with an idea. Details to follow.'
  }
]



class Faq extends Component {
    constructor(props) {
        super(props);
     
    }

   

    render() { 
         
        


      return  <div>   


{/* <!-- FAQ --> */}
    <section className="faq">
        <div className="container-np">
            <div className="faq__wrp">

                {/* <!-- Title --> */}
                <h1 className="faq__title">FAQs</h1>
                {/* <!-- END Title -->

                <!-- Accordion WRP --> */}
                <div className="faq__accordion-wrp">

                    
                    

                    
                    

                {data.map((data, key) => {
            return (
              <div {...{ className: '', key }}>
                <AccordionItem {...data} />
              </div>
            
            )
          })}
                    


                   

                </div>
               

            </div>
        </div>
    </section>

    
        </div>

    } 

}


export default Faq