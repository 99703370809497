import React, {Component} from 'react';
import Wallet from './Wallet';
import {Route} from 'react-router-dom';
import Single from './Single';
import MyApes from './MyApes';
import Faq from './Faq';
import Home from './Home';
import Build from './Build';
import Cards from './Cards';
import Submit from './Submit';
import Locomotive from './Locomotive';

class Main extends Component {
    constructor() {
        super()
     
    }




    
    /**
    *
    *   buildPageId
    *       - Add the Page ID for Styling
    * 
    *   Params
    *       n/a
    *
    **/
    buildPageID() {

        let location = this.props.location,
            hash     = location.hash;

        if( location.hash == '' )
            hash = ( location.pathname == '/' ? 'home' : location.pathname );

        document.body.id = 'p' + hash.replace(/\/|#/g,'');
    }

    /**
    *
    *   componentDidMount / componentDidUpdate
    *       - Trigger the Build page ID function
    * 
    *   Params
    *       n/a
    *
    **/
    componentDidMount(){ this.buildPageID(); }
    componentDidUpdate(){ this.buildPageID(); }
   

    
    /**
    *
    *   render
    *       - Wrap the Site in Locomotive
    *       - Load the Routes
    * 
    *   Params
    *       n/a
    *
    **/
    render() {

        
       
        return ( 
            <Locomotive>

                <Route exact path = "/" render={() => (
                    <>
                        <div>
                            <Wallet {...this.props} />
                        </div>
                        <Home {...this.props} onHistory={history}/>
                    </>
                )}/> 
                

                <Route path= "/MyApes" render = {({history}) => (
                    <>
                        <div>
                            <Wallet {...this.props} />
                        </div>
                        <MyApes {...this.props}  onHistory={history}/>
                    </>
                )}/>
     

                <Route path="/single/:id" render = {(params) => (
                    <>
                        <div>
                            <Wallet {...this.props} />
                        </div>
                        <Single  {...this.props} {...params}/> 
                    </>
                )}/>

                <Route path="/faq" render = {(params) => (
                    <Faq  {...this.props} /> 
                )}/>

                <Route path="/build" render= {(params) => (
                    <Build {...this.props} />
                )}/>

                <Route path="/cards" render= {(params) => (
                    <Cards {...this.props} />
                )}/>

                <Route path="/submit" render = {(params) => (
                    <Submit {...this.props} onHistory={history} />
                )}/>
            
            </Locomotive>
        )
    }

}


export default Main