import React  from 'react';
import ReactTooltip from 'react-tooltip';
import { LocomotiveScrollProvider, useLocomotiveScroll } from 'react-locomotive-scroll';
import { useWeb3React } from "@web3-react/core"
import { injected } from "./connectors"
import { useEagerConnect, useInactiveListener } from './hooks'
import { IpcProvider } from 'web3-core';
import erc721Abii from './apes'
import { HashLink } from 'react-router-hash-link';
import {Link} from 'react-router-dom'



function Wallet(props) {

    const { active, account, library, connector, activate, deactivate } = useWeb3React();
    const [ activatingConnector, setActivatingConnector ] = React.useState();

    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect();

    const [ menuOpen, setMenuValue ] = React.useState(false);

    //Get the Scroll Data
    const {scroll}  = useLocomotiveScroll();
    const updated   = false;

    /**
    *
    *
    *
    *
    **/
     // handle logic to recognize the connector currently being activated
    React.useEffect(() => {

        if (activatingConnector && activatingConnector === connector)
            setActivatingConnector(undefined);
    
    }, [activatingConnector, connector]);



    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEager || !!activatingConnector);
  


    /**
    *
    *
    *
    *
    **/
    React.useEffect(() => {

        if(!active && triedEager)
            props.addWallet(account,library);
    

        props.getStorySubmissions();
        

        if (active && account !== connector && triedEager) {
            props.getStorySubmissions();
            props.addWallet(account,library);
            props.getIsStorySubmittedperWallet(account);
           // props.fetchApesForWallet(account,library);
        }

      }, [active, account,triedEager])


    /**
    *
    *
    *
    *
    **/
    React.useEffect(() => {
        if( scroll ){

        }
    }, [ scroll ]);



    /**
    *
    *   connect
    *       - On Connect
    *
    **/
    async function connect() {
        try {

            await activate(injected);
            props.addWallet(account,library);
        
        } catch (ex) {
        
            console.log(ex)
        
        }
    }
   

    /**
    *
    *   disconnect
    *       - On Disconnect
    *
    *   Params:
    *       n/a
    *
    **/
    async function disconnect() {
        try {
        
            deactivate()
        
        } catch (ex) {
        
            console.log(ex)
        
        }
      }

      // async function getMyApes() {
      //   try {
      //       const contract = new library.eth.Contract(erc721Abii, "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D")
      //       contract.methods.MAX_APES().call().then(function(resp) {
      //           console.log(resp) 
      //        });
           
      //   } catch (ex) {
      //     console.log(ex)
      //   }
      // }
     // const [menuOpen, setValue] = useState(false);
    
    /**
    *
    *   openNav
    *       - Handles Mobile Nav Open
    *
    *   Params:
    *       n/a
    * 
    **/
    function openNav(){
        setMenuValue(true);
        document.getElementById("mySidenav").style.width = "280px";
    }


    /**
    *
    *   closeNav
    *       - Handles Mobile Nav Close
    *
    *   Params:
    *       n/a
    * 
    **/
    function closeNav(){
        if(menuOpen){
            document.getElementById("mySidenav").style.width = "0px";
            setMenuValue(false);
            
        }
    }

    /**
    *
    *   goTo
    *       - Manages the Scroll in Locomotive
    *
    *   Params:
    *       el          - (Element) The Element to interact with
    *       location:   - (Hash) The Hash URL to go to
    * 
    **/
    function goTo( el , location ){
        if( scroll ){

            scroll.scrollTo( location );

        }else{
            
            //Default Behaviour
            el.scrollIntoView({ behavior: 'smooth' });

        }
    }

    var obj = this;
        
    return (
        <nav className="navigation">
            <div className="navigation__container">            

                {/* <!-- Logo --> */}
                <div className="navigation__logo">
                    <a className="navigation__logo-link" href="/">
                        <span className="is-inview" data-animate="fadeIn" style={{ 'animationDelay': '3.55s' }}>F</span>
                        <span className="is-inview" data-animate="fadeIn" style={{ 'animationDelay': '3.60s' }}>O</span>
                        <span className="is-inview" data-animate="fadeIn" style={{ 'animationDelay': '3.65s' }}>R</span>
                        <span className="is-inview" data-animate="fadeIn" style={{ 'animationDelay': '3.70s' }}>E</span>
                        <span className="is-inview" data-animate="fadeIn" style={{ 'animationDelay': '3.75s' }}>V</span>
                        <span className="is-inview" data-animate="fadeIn" style={{ 'animationDelay': '3.80s' }}>E</span>
                        <span className="is-inview" data-animate="fadeIn" style={{ 'animationDelay': '3.85s' }}>R</span> 
                        &nbsp;
                        <span className="is-inview" data-animate="fadeIn" style={{ 'animationDelay': '3.95s' }}>A</span>
                        <span className="is-inview" data-animate="fadeIn" style={{ 'animationDelay': '4.00s' }}>P</span>
                        <span className="is-inview" data-animate="fadeIn" style={{ 'animationDelay': '4.05s' }}>E</span>
                        <span className="is-inview" data-animate="fadeIn" style={{ 'animationDelay': '4.10s' }}>S</span>
                    </a>                
                </div>
                {/* <!-- END Logo --> */}

              



                <span {...{className:'btn-open is-inview', 'data-animate':"fadeIn,moveUp", style: { 'animationDelay': '4.50s' },  onClick: () => {openNav() }}}>
                    <svg width="36" height="36" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L17 1" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
                        <path d="M1 6L17 6" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
                        <path d="M1 11H17" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
                    </svg>                    
                </span>

                {/* <!-- Mobile menu --> */} 
                <div id="mySidenav" className="sidenav">
                    <a href="#" className="closebtn" onClick={closeNav}>&times;</a>
                                                                 
                    <ul className="sidenav__ul">
                        <li className="is-inview" data-animate="fadeIn,moveUp" style={{ 'animationDelay': '4.50s' }}>
                            <ReactTooltip id="toolTipNav" />
                            <a data-for="toolTipNav" href="https://stories.foreverapes.io">View Stories</a>
                        </li>
                        {/*
                        <li className="is-inview" data-animate="fadeIn,moveUp" style={{ 'animationDelay': '4.75s' }}>
                            <HashLink scroll={(el) => goTo(el , '#roadmap') } onClick={closeNav} to="/#roadmap">Roadmap</HashLink>
                        </li>
                        */}

                        <li className="is-inview" data-animate="fadeIn,moveUp" style={{ 'animationDelay': '4.75s' }}>
                            <a href="https://store.foreverapes.io" target="_blank">Shop</a>
                        </li>
                        <li className="is-inview" data-animate="fadeIn,moveUp" style={{ 'animationDelay': '5.00s' }}>
                            <a data-for="toolTipNav" href="https://wall.foreverapes.io">The Wall</a>
                            {/* <HashLink scroll={(el) => goTo( el , '#team') } onClick={closeNav} to="/#team">Team</HashLink> */}
                        </li>
                        <li className="is-inview" data-animate="fadeIn,moveUp" style={{ 'animationDelay': '5.25s' }}>
                            <Link to="/faq" onClick={closeNav}>Faq</Link>
                        </li>
                    </ul>
                    
                    <ul className="sidenav__ul-btn is-inview" data-animate="fadeIn,moveUp" style={{ 'animationDelay': '5.50s' }}>
                        <li>{
                            active ?  
                                <a className="btn-brown" href="#">{account.substring(0, 6)}...{account.substring(account.length - 4)}</a> 
                            : 
                                <a className="btn-brown" href="#" onClick={connect}>Connect wallet</a>
                        }</li>
                    </ul>                                   
                </div>   
                {/* <!-- END Mobile menu --> */} 

            </div> 
        </nav>
    );
}

 export default Wallet
