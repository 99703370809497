import React, { Component, useRef, useEffect } from 'react';
import { LocomotiveScrollProvider, useLocomotiveScroll } from 'react-locomotive-scroll';
import Scroller from '../data/scroll.js';

/**
*
*   ScrollTrigger
*       - The Wrapper
*
*
**/
const ScrollManager = () => {

    const {scroll}  = useLocomotiveScroll();
    const updated   = false;

    //
    useEffect(() => {
        if( scroll ){

            if(!updated)
                setTimeout(function(){
                    scroll.update();
                },3000);

            //On Scroll event
            scroll.on('scroll',(inst)=>{

                //Trigger the Scroll Event
                Scroller.trigger( inst.scroll );

                document.documentElement.classList.toggle(
                    'scrolled',
                    ( inst.scroll.y > 10 )
                );

            });

            //Apply the Class
            Scroller.class = scroll;

        }
    }, [ scroll ]);

    return null;
}


/**
*
*   Locomtive Function Wrapper
*       - The Wrapper
*
*
**/
export default function Locomotive(props){

    const Locomotive = Object.assign({
        options:    {
            smooth:     true,
            /*smartphone: {
                smooth: true
            },*/
            tablet: {
                smooth: true
            }
        },

        watch:      [
            //Array of dependencies to watch for updates

        ]
    },props.settings);


    return (
        <LocomotiveScrollProvider options={ Locomotive.options } watch={ Locomotive.watch }>
            <ScrollManager />
            <div data-scroll-container ref={ useRef(null) }>
                <main>
                    {props.children}
                </main>
            </div>
        </LocomotiveScrollProvider>
    )

}
