import React from "react";
import { Component } from 'react'
import twitterIcon from "../img/icon-twitter.svg"
import discordIcon from "../img/icon-discord.svg"
import arrow from "../img/icon-arrow.png"
import { ethers } from "ethers";

import hash from "hash.js"


class Form extends Component {
  constructor(props) {
    super(props);
    this.checkBoxRef = React.createRef();


    this.state = {
      formValues: {
        story: props.post.story ? props.post.story : "",
        twitter: props.post.twitter ? props.post.twitter : "",
        discord: props.post.discord ? props.post.discord : "",
        checkboxPolice: props.post.checkboxPolice ? props.post.checkboxPolice : false,
        storyLengthRemaining: props.post.story ? props.post.story.length : 0,
        submitButtonValue: "Submit Story",

      },
      formErrors: {
        story: "",
        socialHandle: "",
        checkboxPolice: "",
      },
      formValidity: {
        story: false,
        socialHandle: false,
        checkboxPolice: false

      },
      isSubmitting: false,
      submitted: false
    };
  }

  handleChange = ({ target }) => {
    const { formValues } = this.state;
    formValues['storyLengthRemaining'] = formValues['story'].length > 0 ? formValues['story'].length : 0
    if (target.name == "checkboxPolice") {

      formValues[target.name] = target.checked;
    }
    else
      formValues[target.name] = target.value;

    this.setState({ formValues });
    this.handleValidation(target);
  };



  handleValidation = target => {
    const { name, value } = target;
    const fieldValidationErrors = this.state.formErrors;
    const validity = this.state.formValidity;
    const story = name === "story";
    const socialHandle = (name === "twitter" || name === "discord");
    const checkboxPolice = name === "checkboxPolice";

    if (story || socialHandle || checkboxPolice) {
      // validity[name] = value.length > 0;
      if (story) {

        var storyLength = value.length;
        if (storyLength < 500) {
          validity[name] = false;
          fieldValidationErrors[name] = "*Story must be minimum 500 characters";
        }

        if (storyLength > 1000) {
          validity[name] = false;
          fieldValidationErrors[name] = "*Story must be maximum 1000 characters";
        }
        if (storyLength > 500 && storyLength < 1000) {
          validity[name] = true;
          fieldValidationErrors[name] = "";
        }
      }
      if (socialHandle) {
        var twitterInput = document.getElementById("twitter").value;
        var discordInput = document.getElementById("discord").value;
        if (twitterInput.length > 0 || discordInput.length > 0) {
          validity['socialHandle'] = true;
          fieldValidationErrors['socialHandle'] = "";
        }
        else {
          validity['socialHandle'] = false;
          fieldValidationErrors['socialHandle'] = "*One social handle must be included";
        }

      }
      if (checkboxPolice) {
        if (!target.checked || value == false) {
          validity[name] = false;
          fieldValidationErrors['checkboxPolice'] = "*You must verify consent to submit";
        }
        if (target.checked || value == true) {
          validity[name] = true;
          fieldValidationErrors['checkboxPolice'] = "";
        }
      }



    }

    this.setState({
      formErrors: fieldValidationErrors,
      formValidity: validity
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { formValues, formValidity } = this.state;
    this.setState({ isSubmitting: true });
    this.setState({ formValues });

    for (let key in formValues) {
      let target = {
        name: key,
        value: formValues[key]
      };
      this.handleValidation(target);
    }

    if (Object.values(formValidity).every(Boolean)) {
      formValues["submitButtonValue"] = "Please wait...";
      let messageToSign = "Ape#" + this.props.post.id + "\n";
      messageToSign = messageToSign + "Story:" + formValues.story.substring(0, 10) + "...\n";
      if (formValues.twitter.length != 0)
        messageToSign = messageToSign + "Twitter:" + formValues.twitter + "\n";
      if (formValues.discord.length != 0)
        messageToSign = messageToSign + "Discord:" + formValues.discord + "\n";

        console.log(formValues.story);
        var hashvalue = hash.sha256().update(formValues.story).digest('hex') +"\n"
        messageToSign = messageToSign + "Story Hash:" + hashvalue;
      var date = new Date();
      // date.toGMTString();
      
      messageToSign = messageToSign + "Date:" + date.toGMTString();
     
   
      
      // library
      const account = this.props.appMainStateData.walletAddress;
      // this.props.appMainStateData.library.getSigner(account)
      //   .signMessage(messageToSign)
      //   .then((signature) => {
          // console.log(`Success!\n\n${signature}`)
         // const signerAddr = ethers.utils.verifyMessage(messageToSign, signature); 
          // console.log(signerAddr);
          this.setState({ submitted: true });
          formValues['signature'] = '';
          formValues['wallet'] = account;
          formValues['submissionDate'] = date;
          formValues['signedMessage'] = messageToSign;
          formValues['hashvalue'] = hashvalue;
          formValues['dateString']=date.toGMTString();
         

          this.props.startAddingStory(formValues, this.props.post.id, this.props);

       // })
        // .catch((error) => {
        //   console.log('Failure!' + (error && error.message ? `\n\n${error.message}` : ''))
        //   formValues['submitButtonValue'] = "Submit";
        //   this.setState({ formValues });
        // })


    } else {
      for (let key in formValues) {
        let target = {
          name: key,
          value: formValues[key]
        };
        this.handleValidation(target);
      }
      this.setState({ isSubmitting: false });
    }
  };



  render() {

    const post = this.props.post;
    const { formValues, formErrors, isSubmitting, submitted } = this.state;

    if (submitted)
      return <div className="container-np">
        <div className="contact-us__wrp">
          <h1 className="contact-us__title">Thank you for submitting your story.

          </h1>

        </div>

      </div>
    return <div className="container-np">
      <div className="contact-us__wrp">
        <p className="contact-us__text-top">Submit Your Story</p>
        <h1 className="contact-us__title">Hello, this is my Ape <img src={post.link} alt="Ape Title Icon" width="76" height="76"></img>
          <br></br>and this is my <span className="italic">forever</span> story.
        </h1>
        <form className="form" onSubmit={this.handleSubmit}>

          <div className="form__wrp">
            {/* <!-- Textarea --> */}
            <div className="form__input-wrp error">
              <textarea className="form__textarea" name="story" placeholder={"1.Apes are very personal to us and each of us has incredible stories of how we adopted them, what it means to you and how you plan on using it. We want to share the BAYC lore with the world!"} onChange={this.handleChange} onKeyPress={this.handleChange} value={formValues.story}></textarea>
              <p className="textarea__text">
                Characters count:
                <span className="quantity">{formValues.storyLengthRemaining}</span>
              </p>
              <p className="form__text-error">
                {/* *Story must be minimum 500 characters */} 
                {formErrors.story}
              </p>
            </div>
            <p className="form__text-social">Submit Your Social Handles</p>
            {/* <!-- Input Social --> */}
            <div className="form__input-wrp error">
              <div className="form__input-col">
                <img className="form__input-icon" src={twitterIcon} alt=""></img>
                <input type="text" name="twitter" id="twitter" className="form__input" onChange={this.handleChange} onKeyPress={this.handleChange} value={formValues.twitter}></input>
              </div>
              <div className="form__input-col">
                <img className="form__input-icon" src={discordIcon} alt=""></img>
                <input type="text" name="discord" id="discord" className="form__input" onChange={this.handleChange} onKeyPress={this.handleChange} value={formValues.discord}></input>
                <p className="form__text-error">
                  {/* *One social handle must be included */}
                  {formErrors.socialHandle}
                </p>
              </div>
            </div>
            {/* <!-- Checkbox & BTN --> */}
            <div className="form__input-wrp error">
              <div className="form__input-col">
                <input checked={formValues.checkboxPolice} id="checkboxPolice" type="checkbox" name="checkboxPolice" className="form__checkbox" onChange={this.handleChange}></input>
                <label htmlFor="checkboxPolice">By clicking submit you give permission for Forever Apes to use your Ape on our website and in the coffee table book</label>
                <p className="form__text-error checkbox-error">
                  {/* *You must verify consent to submit */}
                  {formErrors.checkboxPolice}
                </p>
              </div>
              <div className="form__input-col btn-col">
                <button type="submit" className="btn-yellow">
                  {formValues.submitButtonValue}
                  <img src={arrow} alt=">"></img>
                </button>
              </div>
            </div>
           <p className="form_disclaimer"> Disclaimer: Forever Apes reserves the right to edit (and reject) your story.</p>
          </div>
        </form> 

      </div>
    </div>
  }
}
export default Form