let posts = []

const appMainStateData = {
    "posts": posts,
    "walletAddress": '',
    "library": null,
    "walletConnection": null,
    "show_count": true,
    "apesFetched": false,
    "story_count": "Loading...",
    "accept_submission":false,
    "submission_perwallet":false,
   "walletStoryCount":{
       "count":null,
       "postId":null
   },
   position: null,
   fetchStoryCountWalletFlag:false,
   storyCount:"loading..."
}

export default appMainStateData;

