import React from 'react'
import './scss/main.scss'
import {BrowserRouter} from 'react-router-dom'
import { Web3ReactProvider } from '@web3-react/core'
import {createStore,applyMiddleware} from 'redux'
import rootReducer from './redux/reducer'
import {Provider} from 'react-redux'
import App from './Components/App'
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk'
import {Web3Provider} from "@ethersproject/providers";
import { CookiesProvider } from 'react-cookie';



const store = createStore(rootReducer,applyMiddleware(thunk))


function getLibrary(provider) {
  const library           = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}
  

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Web3ReactProvider getLibrary={getLibrary}>
        <CookiesProvider>
          <App/>
        </CookiesProvider>
      </Web3ReactProvider>
      </BrowserRouter>
  </Provider>, 
  document.getElementById('root')
)
