const Scroller = {

    events: {},
    class:  null,

    //Bind an Event
    bind: ( type , callback) => {
        Scroller.events[ type ] = callback;
    },

    //Remove an Event
    unbind: ( type ) => {
        delete Scroller.events[ type ];
    },

    //Trigger a Scroll Event
    trigger: (scroll) => {
        for( var type in Scroller.events ){
            Scroller.events[ type ](scroll);
        }
    }


};

export default Scroller;

