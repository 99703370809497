import React from 'react'
import Photo from './Photo'
import parse from 'html-react-parser';

function MyApes(props) {
    
    let walletNotConnectedORNoApes = false,
        message                    = '';
    
    console.log('??');
    if (props.appMainStateData.walletConnection === null){
        const loading = true;
    }

    
    //Wallet Needs to Connect
    if (props.appMainStateData.walletConnection == false) {
        console.log("Wallet not connected");
        walletNotConnectedORNoApes = true;
        message = "Please connect your wallet to select an ape.";
    }


    //No Apes
    if (
        props.appMainStateData.apesFetched      == true &&
        props.appMainStateData.walletConnection == true &&
        props.appMainStateData.posts.length     == 0
    ){
        console.log("Wallet has no Apes");
        walletNotConnectedORNoApes = true;
        message = "The connected wallet doesn't own any apes. Only Ape owners can submit a story"
        console.log('?????');
    }


    //Max Stories Reached
    if (
        props.appMainStateData.storyCount           === 0 &&
        props.appMainStateData.accept_submission    == false &&
        props.appMainStateData.show_count           == true
    ){
       // console.log(props.appMainStateData);
        console.log("Maximum story submissions reached");
        walletNotConnectedORNoApes = true;
        message = "All 150 story contributions have been submitted. If you missed your opportunity to write, no stress - you are still eligible to mint our upcoming RISE token which gives you access to redeem a physical book. Follow us on our Twitter (<a style=\"color:#8d673f\" href=\"https://twitter.com/Forever_Apes\">https://twitter.com/Forever_Apes</a>) for all of the latest news and updates."

    }

    

    

    //   if(props.appMainStateData.walletConnection &&  props.appMainStateData.walletAddress!='')
    //     console.log("tried to connect to wallet .. wallet is not connected");

    if (walletNotConnectedORNoApes) { 
        console.log(1);
        return (
            <div>
                <section className="contact-us select">

                    <div className="container-np">
                        <div className="contact-us__wrp">
                            {/* <p className="contact-us__text-top">Submit Your Story</p> */}
                            <h1 className="contact-us__title">Story submission is closed</h1>
                            <div className="select-ape">

                                <p className="contact-us__message">{parse(message)}</p>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }else
    if(props.appMainStateData.show_count==true){
        console.log(2);
        return (
            <div>
                <section className="contact-us select">

                    <div className="container-np">
                        <div className="contact-us__wrp">
                            {/* <p className="contact-us__text-top">Submit Your Story</p> */}
                            <h1 className="contact-us__title">Story submission is closed</h1>
                            <div className="select-ape">

                                {
                                    props.appMainStateData.posts.sort(function (x, y) {
                                        return y.id - x.id
                                    }).map(
                                        (post, index) => <Photo key={index} post={post} {...props} index={index} />
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }else{
        console.log(3);
        return (
            <div>
                <section className="contact-us select">

                    <div className="container-np">
                        <div className="contact-us__wrp">
                            {/* <p className="contact-us__text-top">Submit Your Story</p> */}
                            <h1 className="contact-us__title">Story submission is closed</h1>

                        </div>
                    </div>
                </section>
            </div>
        );
    }
}



export default MyApes