import { combineReducers } from 'redux';
import appMainStateData from '../data/posts';
import riseMintData from '../data/rise';


function riseMint(state = riseMintData, action) {
  switch (action.type) {
    case 'ADD_Whitelist':


      return {
        "whitelist": action.whitelistQuantity,
        "mintCount": state.mintCount,
        "transactionHash": state.transactionHash,
        "txdetails":state.txdetails,
        "err":state.err,
        "saleState":state.saleState
      }

    case 'ADD_MintCount':
      return {
        "whitelist": state.whitelist,
        "mintCount": action.totalSupply,
        "transactionHash": state.transactionHash,
        "txdetails":state.txdetails,
        "err":state.err,
        "saleState":action.saleState
      }

    case 'ADD_Transactionhash':
      return {
        "whitelist": state.whitelist,
        "mintCount": state.mintCount,
        "transactionHash": action.hash,
        "txdetails":null,
        "err":null,
        "saleState":state.saleState
      }

      case 'ADD_Transactionhash':
      return {
        "whitelist": state.whitelist,
        "mintCount": state.mintCount,
        "transactionHash": state.transactionHash,
        "txdetails":action.txdetails,
        "err":state.err,
        "saleState":state.saleState
      }

      case 'ADD_ErrorMessage':
      return {
        "whitelist": state.whitelist,
        "mintCount": state.mintCount,
        "transactionHash": state.transactionHash,
        "txdetails":state.txdetails,
        "err":action.err,
        "saleState":state.saleState
      }

      case 'ADD_TransactionProcessed':
        let count = state.mintCount+action.count;
        let wl = state.whitelist
        if(state.saleState==1)
         wl = state.whitelist - action.count
      return {
        "whitelist": wl,
        "mintCount": count,
        "transactionHash": state.transactionHash,
        "txdetails":action.txdetails,
        "err":state.err,
        "saleState":state.saleState
      }


    default: return state
  }
}

function postsfun(state = appMainStateData, action) {

  switch (action.type) {
    case 'REMOVE_PICTURE': {
      const postsTemp = [...state.posts.slice(0, action.i), ...state.posts.slice(action.i + 1)]

      return { "posts": postsTemp,
       "walletAddress": state.walletAddress,
        "library": state.library, 
        "walletConnection": state.walletConnection, 
        "apesFetched": state.apesFetched,
        "storyCount": state.story_count,
        "accept_submission":state.accept_submission,
        "show_count":state.show_count,
        "walletStoryCount":state.walletStoryCount,
      "fetchStoryCountWalletFlag":state.fetchStoryCountWalletFlag,
      "storyCount":state.storyCount }


    }
    case 'ADD_PICTURE': {
      var p = action.id;
      const postsTemp = [action.id, ...state.posts];
      return { "posts": postsTemp, 
      "walletAddress": state.walletAddress, 
      "library": state.library, 
      "walletConnection": state.walletConnection, 
      "apesFetched": state.apesFetched,
      "storyCount": state.story_count,
      "accept_submission":state.accept_submission,
      "show_count":state.show_count,
      "walletStoryCount":state.walletStoryCount,
      "fetchStoryCountWalletFlag":state.fetchStoryCountWalletFlag,
      "storyCount":state.storyCount }
    }

    case 'LOAD_POSTS': {
      const postsTemp = action.posts
      return { "posts": postsTemp, 
      "walletAddress": state.walletAddress, 
      "library": state.library, 
      "walletConnection": state.walletConnection, 
      "apesFetched": true,
      "storyCount": state.story_count,
      "accept_submission":state.accept_submission,
      "show_count":state.show_count,
      "walletStoryCount":state.walletStoryCount,
      "fetchStoryCountWalletFlag":state.fetchStoryCountWalletFlag,
      "storyCount":state.storyCount }
    }

    case 'ADD_WALLET': {
      const postsTemp = [...state.posts];

      if (!action.address)
        var walletConnection = false;
      else
        var walletConnection = true;

      return { "posts": postsTemp, "walletAddress": action.address, "library": action.library, "walletConnection": walletConnection, "apesFetched": false,"storyCount": state.story_count,"accept_submission":state.accept_submission,"show_count":state.show_count,"walletStoryCount":state.walletStoryCount,
      "fetchStoryCountWalletFlag":state.fetchStoryCountWalletFlag,"storyCount":state.storyCount }
    }

    case 'ADD_Story': {
      let postsarray = [];
      let postsTemp = action.appMainStateData.posts;
      for (var i = 0; i < postsTemp.length; i++) {
        if (postsTemp[i]['id'] == action.postId) {

          postsTemp[i]['story'] = action.formvalues.story;
          postsTemp[i]['twitter'] = action.formvalues.twitter;
          postsTemp[i]['discord'] = action.formvalues.discord;
          postsTemp[i]['checkboxPolice'] = action.formvalues.checkboxPolice;
        }

        postsarray.push(postsTemp[i]);

      }
      return { "posts": postsarray, 
      "walletAddress": state.walletAddress, 
      "library": action.appMainStateData.library, 
      "walletConnection": action.appMainStateData.walletConnection, 
      "apesFetched": state.apesFetched,
      "storyCount": state.story_count,
      "accept_submission":state.accept_submission,
      "show_count":state.show_count,
      "walletStoryCount":state.walletStoryCount,
      "fetchStoryCountWalletFlag":state.fetchStoryCountWalletFlag,
      "storyCount":state.storyCount}
    }
    case 'ADD_Story_Count': {

      const postsTemp = [...state.posts];
      let accept_submission=false;
      if(action.storyCount>0)
        accept_submission=true;

      return { "posts": postsTemp,
               "walletAddress": state.walletAddress, 
               "library": state.library,
               "apesFetched": state.apesFetched,
               "walletConnection": state.walletConnection,
               "storyCount":action.storyCount,
               "accept_submission":accept_submission,
               "show_count":true,
               "walletStoryCount":state.walletStoryCount,
               "fetchStoryCountWalletFlag":state.fetchStoryCountWalletFlag
              }
    }
    
    case 'ADD_Wallet_Story_Count': {

      const postsTemp = [...state.posts];
      // let accept_submission=false;
      // if(action.storyCount>0)
      //   accept_submission=true;

      return { "posts": postsTemp,
               "walletAddress": state.walletAddress, 
               "library": state.library,
               "apesFetched": state.apesFetched,
               "walletConnection": state.walletConnection,
               "storyCount":state.storyCount,
               "accept_submission":state.accept_submission,
               "show_count":true,
               "walletStoryCount":action.walletStoryCount,
               "fetchStoryCountWalletFlag":true
     
              }
    }

 
    default: return state
  }
}


const rootReducer = combineReducers({ postsfun, riseMint })

export default rootReducer



