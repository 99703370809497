import React , { Component, useRef } from 'react';
import { instanceOf } from 'prop-types';
import parse from 'html-react-parser';
import {Link} from 'react-router-dom';
import Modal from 'react-modal';
import { LocomotiveScrollProvider, useLocomotiveScroll } from 'react-locomotive-scroll';
import { withCookies, Cookies } from 'react-cookie';
import { StickyContainer, Sticky } from 'react-sticky';
import Scroller from '../data/scroll.js';


import iconTwitter from '../img/Twitter-logo.svg';
import iconDiscord from '../img/home/icon-discord.svg';
import iconInstagram from '../img/home/icon-instagram.svg';
import imgHero from '../img/meebs-hero.png';
import imgCharacter from '../img/character.png';
import imgCharacter2 from '../img/meebit-2.png';
import meekit from '../img/bg-block.jpg';
import imgBlock from '../img/brick.png';

import ac from '../img/01 - ApeCoin.jpeg';

import m1 from '../img/meebits/9141 - NGBxBen.jpeg';
import m2 from '../img/meebits/14709 - Marcel.jpeg';
import m3 from '../img/meebits/17407 - Danny Greene.jpeg';
import m4 from '../img/meebits/842 - CPyrc-II.jpeg';
import m5 from '../img/meebits/1573 - l3id.jpeg';
import m6 from '../img/meebits/2814  - BUGS.jpeg';
import m7 from '../img/meebits/3392 - Linsanity.jpeg';
import m8 from '../img/meebits/3985 - BAYC3593.jpeg';
import m9 from '../img/meebits/4095.jpeg';
import m10 from '../img/meebits/5667 - Niftynaut.jpeg';
import m11 from '../img/meebits/6113 - CDY.jpeg';
import m12 from '../img/meebits/6307 - 12GAUGE.jpeg';
import m13 from '../img/meebits/6785 - SEV.jpeg';
import m14 from '../img/meebits/6806 - Obolazul.jpeg';
import m15 from '../img/meebits/7492 - Bailey.jpeg';
import m16 from '../img/meebits/8169 - Mala.jpeg';
import m17 from '../img/meebits/8818 - Niftynaut.jpeg';
import m18 from '../img/meebits/8900 - TokenJuggler.jpeg';
import m19 from '../img/meebits/9294 - DegenSherpa.jpeg';
import m20 from '../img/meebits/9410 - Niftynaut.jpeg';
import m21 from '../img/meebits/10458 - EKCS.jpeg';
import m22 from '../img/meebits/11442 - SoccerGreg.jpeg';
import m23 from '../img/meebits/12380 - Obolazul.jpeg';
import m24 from '../img/meebits/13059 - CPyrc.jpeg';
import m25 from '../img/meebits/13185 - PaperD.jpeg';
import m26 from '../img/meebits/13559 - CPyrc.jpeg';
import m28 from '../img/meebits/14341 - BigGameJames.jpeg';
import m29 from '../img/meebits/14457 - qoob.jpeg';
import m30 from '../img/meebits/14688.jpeg';
import m31 from '../img/meebits/15060 - Mlitman.jpeg';
import m32 from '../img/meebits/16703 - Bojangle.jpeg';
import m33 from '../img/meebits/17955 - Harwoo.jpeg';
import m34 from '../img/meebits/7457 - Harry Lee Media.jpeg';
import m35 from '../img/meebits/13117 - Godmosword.jpeg';
import m36 from '../img/meebits/15919 - Luke.jpeg';
///
import m37 from '../img/meebits/Bugs - 4095.jpeg';
import m38 from '../img/meebits/8070 - Maaria.jpeg';
import m39 from '../img/meebits/18577 - Vandy.jpeg';
import m40 from '../img/meebits/10630 - BoJangle.jpeg';
import m41 from '../img/meebits/14219 - RUNJ.jpeg';

import a1 from '../img/apes/Gerry - 3155.jpeg';
import a2 from '../img/apes/PPMan - 254.jpeg';
import a3 from '../img/apes/Vera - 3679.jpeg';
import a4 from '../img/apes/Vulkan - 18513.jpeg';
import a5 from '../img/apes/Morphix - 8869.jpeg';
///
import a6 from '../img/apes/CryptoLogically - 793.jpeg';
import a7 from '../img/apes/TheSmarmyBum - 1589.jpeg';
import a8 from '../img/apes/ChefTuring - 3338.jpeg';
import a9 from '../img/apes/4452 - RevolverX.jpeg';
import a10 from '../img/apes/5536 - Karsun.jpeg';
import a11 from '../img/apes/5989 - Vandy.jpeg';
import a12 from '../img/apes/8028 - Brazy.jpeg';
import a13 from '../img/apes/8401 - Allo.jpeg';
import a14 from '../img/apes/9711 - Memo Mx.jpeg';
import a15 from '../img/apes/26362 - DaNameKai.jpeg';
import a16 from '../img/apes/29096 - RedBerry.jpeg';
import a17 from '../img/apes/9127 - Richard.jpeg';


//Apecoin
import f1 from '../files/apecoin/apecoin.pdf';

//Apes
import f2 from '../files/apes/254-ppman.pdf';
import f3 from '../files/apes/793-cryptologically.pdf';
import f4 from '../files/apes/1589-thesmarmybum.pdf';
import f5 from '../files/apes/3155-gerry.pdf';
import f6 from '../files/apes/3338-chefturing.pdf';
import f7 from '../files/apes/3679-vera.pdf';
import f8 from '../files/apes/4452-revolverx.pdf';
import f9 from '../files/apes/5536-karsun.pdf';
import f10 from '../files/apes/5989-vandy.pdf';
import f11 from '../files/apes/8028-brazy.pdf';
import f12 from '../files/apes/8401-allo.pdf';
import f13 from '../files/apes/8869-morphix.pdf';
import f14 from '../files/apes/9127-richard.pdf';
import f15 from '../files/apes/9711-memomx.pdf';
import f16 from '../files/apes/18513-vulkan.pdf';
import f17 from '../files/apes/26362-danamekai.pdf';
import f18 from '../files/apes/29096-redberry.pdf';

//Meebits
import f19 from '../files/meebits/3392-linsanity.pdf';
import f20 from '../files/meebits/842-cpyrc-ii.pdf';
import f21 from '../files/meebits/1573-l3id.pdf';
import f22 from '../files/meebits/3985-bayc3593.pdf';
import f23 from '../files/meebits/4095-bugs.pdf';
import f24 from '../files/meebits/5667-niftynaut.pdf';
import f25 from '../files/meebits/6307-12gauge.pdf';
import f26 from '../files/meebits/6785-sev.pdf';
import f27 from '../files/meebits/6806-obolazul.pdf';
import f28 from '../files/meebits/7457-harryleemedia.pdf';
import f29 from '../files/meebits/7492-bailey.pdf';
import f30 from '../files/meebits/8070-maaria.pdf';
import f31 from '../files/meebits/8169-mala.pdf';
import f32 from '../files/meebits/8818-niftynaut.pdf';
import f33 from '../files/meebits/8900-tokenjuggler.pdf';
import f34 from '../files/meebits/9141-ngbxben.pdf';
import f35 from '../files/meebits/9294-degensharpa.pdf';
import f36 from '../files/meebits/9410-niftynaut.pdf';
import f37 from '../files/meebits/10458-ekcs.pdf';
import f38 from '../files/meebits/10630-bojangle.pdf';
import f39 from '../files/meebits/12380-obolazul.pdf';
import f40 from '../files/meebits/13117-godmosword.pdf';
import f41 from '../files/meebits/13185-paperd.pdf';
import f42 from '../files/meebits/14219-runj.pdf';
import f43 from '../files/meebits/14341-biggamejames.pdf';
import f44 from '../files/meebits/14709-marcel.pdf';
import f45 from '../files/meebits/15060-miltman.pdf';
import f46 from '../files/meebits/15919-luke.pdf';
import f47 from '../files/meebits/17407-danny.pdf';
import f48 from '../files/meebits/17955-harwoo.pdf';
import f49 from '../files/meebits/18577-vandy.pdf';


class Build extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };


    /**
    *
    *   constructor
    *       - The Initialization Function
    *
    *   Params
    *       props:      (Object) The Properties passed to the component
    *
    **/
    constructor(props) {
        super(props);

        const { cookies } = props;

        this.position = 0;

        this.state = {
            builder:        ( cookies.get('builder') || null ),
            approved:       ( cookies.get('builder') ? true : false ),
            modal:          false,
            step:           0,
            handle:         '',
            validating:     false,
            filter:         null,
            preloaded:      false,
            error:          null
        };

        this.downloads = [
            {
                status:         'available',
                image:          ac,
                owner:          'ApeCoin',
                instructions:   f1,
                filter:         'apecoin'
            },{
                status:         'available',
                image:          m1,
                owner:          'NGBxBen',
                instructions:   f34,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m2,
                owner:          'Marcel',
                instructions:   f44,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m3,
                owner:          'Danny Greene',
                instructions:   f47,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m4,
                owner:          'CPyrc-II',
                instructions:   f22,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m5,
                owner:          'l3id',
                instructions:   f21,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m7,
                owner:          'Linsanity',
                instructions:   f19,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m8,
                owner:          'BAYC3593',
                instructions:   f22,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m10,
                owner:          'Niftynaut',
                instructions:   f24,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m12,
                owner:          '12GAUGE',
                instructions:   f25,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m13,
                owner:          'SEV',
                instructions:   f26,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m14,
                owner:          'Obolazul',
                instructions:   f27,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m15,
                owner:          'Bailey',
                instructions:   f29,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m16,
                owner:          'Mala',
                instructions:   f31,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m17,
                owner:          'Niftynaut',
                instructions:   f32,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m18,
                owner:          'TokenJuggler',
                instructions:   f33,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m19,
                owner:          'DegenSherpa',
                instructions:   f35,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m20,
                owner:          'Niftynaut',
                instructions:   f36,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m21,
                owner:          'EKCS',
                instructions:   f37,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m23,
                owner:          'Obolazul',
                instructions:   f39,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m25,
                owner:          'PaperD',
                instructions:   f41,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m28,
                owner:          'BigGameJames',
                instructions:   f43,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m31,
                owner:          'Mlitman',
                instructions:   f45,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m33,
                owner:          'Harwoo',
                instructions:   f48,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m34,
                owner:          'Harry Lee Media',
                instructions:   f28,
                filter:         'meebits'
            },{
                status:         'available',
                image:          a1,
                owner:          'Gerry',
                instructions:   f5,
                filter:         'apes'
            },{
                status:         'available',
                image:          a4,
                owner:          'Vulkan',
                instructions:   f16,
                filter:         'apes'
            },{
                status:         'available',
                image:          a5,
                owner:          'Morphix',
                instructions:   f13,
                filter:         'apes'
            },{
                status:         'available',
                image:          a3,
                owner:          'Vera',
                instructions:   f7,
                filter:         'apes'
            },{
                status:         'available',
                image:          a2,
                owner:          'PPMan',
                instructions:   f2,
                filter:         'apes'
            },{
                status:         'available',
                image:          m36,
                owner:          'Luke',
                instructions:   f46,
                filter:         'meebits'

            },{
                status:         'available',
                image:          m41,
                owner:          'RUNJ',
                instructions:   f42,
                filter:         'meebits'

            },{
                status:         'available',
                image:          m35,
                owner:          'Godmosword',
                instructions:   f40,
                filter:         'meebits'
            },{
                status:         'available',
                image:          a6,
                owner:          'CryptoLogically',
                instructions:   f3,
                filter:         'apes'
            },{
                status:         'available',
                image:          a7,
                owner:          'TheSmarmyBum',
                instructions:   f4,
                filter:         'apes'
            },{
                status:         'available',
                image:          a8,
                owner:          'Chef Turing',
                instructions:   f6,
                filter:         'apes'
            },{
                status:         'available',
                image:          m37,
                owner:          'Bugs',
                instructions:   f23,
                filter:         'meebits'
            },{
                status:         'available',
                image:          a9,
                owner:          'RevolverX',
                instructions:   f8,
                filter:         'apes'
            },{
                status:         'available',
                image:          a10,
                owner:          'Karsun',
                instructions:   f9,
                filter:         'apes'
            },{
                status:         'available',
                image:          a11,
                owner:          'Vandy',
                instructions:   f10,
                filter:         'apes'
            },{
                status:         'available',
                image:          a12,
                owner:          'Brazy',
                instructions:   f11,
                filter:         'apes'
            },{
                status:         'available',
                image:          m38,
                owner:          'Maaria',
                instructions:   f30,
                filter:         'meebits'
            },{
                status:         'available',
                image:          a13,
                owner:          'Allo',
                instructions:   f12,
                filter:         'apes'
            },{
                status:         'available',
                image:          a17,
                owner:          'Richard',
                instructions:  f14,
                filter:         'apes'
            },{
                status:         'available',
                image:          a14,
                owner:          'Memo Mx',
                instructions:   f15,
                filter:         'apes'
            },{
                status:         'available',
                image:          m40,
                owner:          'BoJangle',
                instructions:   f38,
                filter:         'meebits'
            },{
                status:         'available',
                image:          m39,
                owner:          'Vandy',
                instructions:   f49,
                filter:         'meebits'
            },{
                status:         'available',
                image:          a15,
                owner:          'DaNameKai',
                instructions:   f17,
                filter:         'apes'
            },{
                status:         'available',
                image:          a16,
                owner:          'RedBerry',
                instructions:   f18,
                filter:         'apes'
            }/*{
                status:         'available',
                image:          ,
                owner:          '',
                instructions:   '',
                filter:         ''

            },*/
        ].reverse();

        this.handleClick    = this.handleClick.bind(this);
        this.goTo           = this.goTo.bind(this);
        this.open           = this.open.bind(this);
        this.handle         = this.handle.bind(this);
        this.closeModal     = this.closeModal.bind(this);
        this.openModal      = this.openModal.bind(this);
        this.validate       = this.validate.bind(this);

    }


    /**
    *
    *   componentDidMount
    *       -
    *
    *   Params
    *       n/a
    *
    **/
    componentDidMount(){

        var obj = this;

        //On Scroll
        Scroller.bind( 'build' , (scroll)=>{

            let $el         = document.querySelector('.restricted'),
                $nav        = document.querySelector('.navigation'),
                navHeight   = $nav.offsetHeight,
                width       = Math.max(
                    document.body.scrollWidth,
                    document.documentElement.scrollWidth,
                    document.body.offsetWidth,
                    document.documentElement.offsetWidth,
                    document.documentElement.clientWidth
                )

            //Force it back to the top
            if( scroll.y < 25 )
                scroll.y = 0;

            //$nav.style.transform = 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ' + scroll.y + ', 0, 1)';

            if( width > 600 ){
                if( $el )
                    $el.style.top = ( scroll.y + navHeight + ( ( ( window.innerHeight - navHeight ) / 2 ) - ($el.offsetHeight / 2 ) ) ) + 'px';
            }else{
                if( $el.style.top )
                    $el.style.top = null;
            }
        });

    }


    /**
    *
    *   componentWillUnmount
    *       -
    *
    *   Params
    *       n/a
    *
    **/
    componentWillUnmount(){

        //Remove Build Event
        Scroller.unbind( 'build' );

    }



    handleClick(e){
        //Do Nothing
    }

    /**
    *
    *   handle
    *       - Set the Handle State
    * 
    *   Params
    *       n/a
    * 
    **/
    handle = (e) => {
        this.setState({
            handle: e.target.value.replace(/[^a-z0-9_-]/gi,'')
        });
    }

    /**
    *
    *   open
    *       - Toggle the Accordion
    *
    *   Params
    *       n/a
    *
    **/
    open = (e) => {
        e.currentTarget.classList.toggle('opened');
        Scroller.class.update();
    }




    /**
    *
    *   openModal
    *       - Opens the Modal
    *
    *   Params
    *       n/a
    *
    **/
    openModal = (e) => {
        this.setState({
            modal: true
        });
    }




    /**
    *
    *   closeModal
    *       - Closes the Modal
    *
    *   Params
    *       n/a
    *
    **/
    closeModal = (e) => {
        this.setState({
            modal: false
        });
    }




    /**
    *
    *   goTo
    *       - Manages the Scroll in Locomotive
    *
    *   Params:
    *       el          - (Element) The Element to interact with
    *       location:   - (Hash) The Hash URL to go to
    *
    **/
    goTo = ( el , location ) => {
        if( Scroller.class ){

            Scroller.class.scrollTo( location );

        }else{

            //Default Behaviour
            el.scrollIntoView({ behavior: 'smooth' });

        }
    }




    /**
    *
    *   submit
    *       - Submit the Form
    * 
    *   Params
    *       n/a
    * 
    **/
    validate = (e) => {

        const { cookies } = this.props;

        let obj     = this,
            message = 'We\'ve also added new Meebits to build.',
            url     = 'https://server.foreverapes.io/twitter/validate?handle=' + this.state.handle + '&locate=' + encodeURIComponent( message )

        this.setState({
            validating: true,
            error:      null
        });

        //Run the Request
        fetch(url).then( (response) => {
            return response.json();
        }).then( (response) => {

            response.result = true;

            obj.setState({
                validating: false,
                approved:   ( response.result )
            });

            cookies.set('builder', this.state.handle, { path: '/' });

            obj.setState({
                builder:    this.state.handle,
                available:  true,
                error:      ( !response.result ? 'We were unable to verify your retweet, please try again' : null )
            })

            if( response.result ){
                obj.closeModal();
            }

        })

    }








    /**
    *
    *   download
    *       - Download a File
    *
    *   Params
    *       n/a
    *
    **/
    download = (project) => {
        console.log(project);
        return;
    }




    
    /**
    *
    *   render
    *       - Render the Submit Function
    * 
    *   Params
    *       n/a
    * 
    **/
    render(){

        var setStep = (step) => {
            this.setState({
                step: step
            })
        };

        let stepClass = ( this.state.approved ? 'active' : null );

        //
        return (
            <>
                <div id="preloader" data-preloaded={ this.state.preloaded }>
                    <svg version="1.1" viewBox="0 0 33 6" style={{'enable-background':'new 0 0 33 6'}} xmlSpace="preserve">
                        <g>
                            <path d="M0,6V0h0.9v5.1h3V6H0z"/>
                            <path d="M5.4,6V5.6H4.9V5.1H4.5V4.7H4.1V1.3h0.4V0.9h0.4V0.4h0.4V0h2.6v0.4h0.4v0.4h0.4v0.4h0.4v3.4H8.8v0.4H8.4v0.4H7.9V6H5.4z
                                 M7.5,5.1V4.7h0.4V4.3h0.4V1.7H7.9V1.3H7.5V0.9H5.8v0.4H5.4v0.4H4.9v2.6h0.4v0.4h0.4v0.4H7.5z"/>
                            <path d="M9.9,6V4.7h0.4V3.4h0.4V2.1h0.4V0.9h0.4V0h1.3v0.9h0.4v1.3h0.4v1.3h0.4v1.3h0.4V6h-0.9V4.7h-3V6H9.9z M13.3,3.9V3.4h-0.4
                                V2.1h-0.4V1.3H12v0.9h-0.4v1.3h-0.4v0.4H13.3z"/>
                            <path d="M15.4,6V0h3.4v0.4h0.4v0.4h0.4v0.4h0.4v3.4h-0.4v0.4h-0.4v0.4h-0.4V6H15.4z M18.4,5.1V4.7h0.4V4.3h0.4V1.7h-0.4V1.3h-0.4
                                V0.9h-2.1v4.3H18.4z"/>
                            <path d="M21,6V0h0.9v6H21z"/>
                            <path d="M22.7,6V0H24v0.9h0.4v0.9h0.4v0.9h0.4v0.9h0.4v0.9h0.4V0H27v6h-1.3V5.1h-0.4V4.3h-0.4V3.4h-0.4V2.6H24V1.7h-0.4V6H22.7z"/>
                            <path d="M29.1,6V5.6h-0.4V5.1h-0.4V4.7h-0.4V1.3h0.4V0.9h0.4V0.4h0.4V0h2.6v0.4h0.4v0.4h0.4v0.4H33v0.9h-0.9V1.7h-0.4V1.3h-0.4V0.9
                                h-1.7v0.4h-0.4v0.4h-0.4v2.6h0.4v0.4h0.4v0.4h1.7V4.7h0.4V4.3h0.4V3.9h-1.3V3H33v3h-0.9V5.1h-0.4v0.4h-0.4V6H29.1z"/>
                        </g>
                    </svg>
                </div>
                <nav className="navigation" data-scroll data-scroll-sticky data-scroll-target="#root > div > main">
                    <div className="hello-bar">
                        <p>
                            Have any questions or want to get your own build? Check out our <a onClick={(e)=>{ this.goTo(e,'#questions') }}>FAQ</a>
                        </p>
                    </div>
                    <div className={`navigation__container ${ this.state.approved ? 'approved' : '' }`}>

                        {/* <!-- Logo --> */}
                        <div className="navigation__logo">
                            <a className="navigation__logo-link" href="/">
                                FOREVER APES
                            </a>                
                        </div>
                        {/* <!-- END Logo --> */}

                        {/* <!-- Mobile menu --> */}
                            <div className="social">

                                { /*!this.state.approved ? (
                                    <a onClick={() => { this.openModal() }}>
                                        <img src={ iconTwitter } />
                                        <span>
                                            Tweet to Unlock
                                        </span>
                                    </a>
                                ) : (
                                    <span>
                                        <img src={ iconTwitter } />
                                        <span>&#x2713;</span>
                                    </span>
                                )*/ }

                                <span>
                                    <img src={ iconTwitter } />
                                    <span>&#x2713;</span>
                                </span>
                            </div>
                        {/* <!-- END Mobile menu --> */} 

                    </div> 
                </nav>

                { /* this.state.submitted ? <Success /> : this.form() */ }

                <section className="header">
                    <iframe src={ 'https://player.vimeo.com/video/784269281?h=2828029737&badge=0&autopause=0&autoplay=1&loop=1&player_id=0&background=1&app_id=58479/embed' } allow="autoplay" frameBorder="0" onLoad={()=>{
                        this.setState({
                            preloaded: true
                        })
                    }}></iframe>
                    <div>
                        <h1>
                            Let's build
                            <span>
                                <em>Meebs</em>
                                <em style={{ animationDelay:'2s' }}>Apes</em>
                            </span>
                        </h1>
                    </div>
                </section>

                {/*
                <section className="steps">
                    <div className="container">
                        <h2>Easy as 1,2,3.</h2>
                        <ol>
                            <li className="active">
                                <div className="image">
                                    <img src={ imgBlock } />
                                </div>
                                <p>
                                    Share <a onClick={()=>{ this.openModal() }}>this</a> Tweet to unlock
                                    <img src={ iconTwitter } />
                                </p>
                            </li>
                            <li className="active">
                                <div className="image">
                                    <img src={ imgBlock } />
                                </div>
                                <p>
                                    Download free instructions and part lists.
                                </p>
                            </li>
                            <li className="active">
                                <div className="image">
                                    <img src={ imgBlock } />
                                </div>
                                <p>
                                    Secure your brick kit from <a href="https://www.lego.com/en-ca/pick-and-build/pick-a-brick" target="_blank">Lego.com</a>
                                </p>
                            </li>
                        </ol>
                    </div>
                </section>
                */ }

                <section className="projects">
                    {
                        this.state.approved ?
                            (
                                <div className="container">

                                    <h2>Select a Collection</h2>

                                    <ul className="filters">{
                                        ['View All','Meebits','Apes'/*,'Mutants'*/].map( ( type , i ) => {

                                            var key         = type.toLowerCase(),
                                                selected    = this.state.filter == key || ( key == 'view all' && !this.state.filter ) ? 'selected' : '';

                                            return (
                                                <li key={ key } onClick={()=>{
                                                    this.setState({
                                                        filter: ( this.state.filter == key || key == 'view all' ? null : key )
                                                    });
                                                }} className={`${selected}`}>{ type }</li>
                                            )

                                        })
                                    }
                                    </ul>


                                </div>
                            ) : ''
                    }

                    <div className="wrap" data-state={ this.state.approved }>
                        <div>
                            <div className="list">
                                {
                                    ( list => {

                                        //
                                        let sorted = {
                                            available:      [],
                                            unavailable:    []
                                        };

                                        //
                                        list.forEach( row => sorted[ row.status ].push( row ) );

                                        //
                                        sorted.available.sort((a,b)=>{
                                            if(
                                                a.filter == 'apecoin'
                                                ||
                                                a.owner.toLowerCase().indexOf('pp') > -1
                                                ||
                                                a.owner.toLowerCase().indexOf('nbg') > -1
                                            ) return -5;
                                            return ( 0.5 - Math.random() );
                                        });

                                        //
                                        sorted.unavailable.sort( (a,b) => {
                                            return ( 0.5 - Math.random() );
                                        })

                                        //
                                        return sorted.available.concat( sorted.unavailable );

                                    })( this.downloads ).map( ( project , i ) => {

                                        if( this.state.filter && project.filter != this.state.filter && project.filter != 'apecoin' )
                                            return;

                                        return (
                                            <div className="project" key={ i } data-state={ project.status }>
                                                <div className="image">
                                                    <img src={ project.image } />
                                                </div>
                                                <p className="details">
                                                    <span className="tag">Owner</span>
                                                    <span className="owner">{ project.owner }</span>
                                                </p>
                                                {
                                                    /*this.state.approved &&*/ project.instructions ?
                                                    (
                                                        <a href={ project.instructions } className="button" target="_blank">
                                                            Download Instructions
                                                        </a>
                                                    )
                                                    :
                                                    (<span className="button">Coming Soon</span>)
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            {/*
                                !this.state.approved ?
                                    (
                                        <div className="activate"><div></div></div>
                                    )
                                :
                                    ''
                            */}
                        </div>
                        {/*
                            !this.state.approved ?
                            (
                                <div className="restricted">
                                    <div className="start">
                                        <h3>Content Locked</h3>
                                        <p data-desktop>
                                            Access all downloadable content by sending a Retweet. <br />
                                            Click the link below to get started.
                                        </p>
                                        <p data-mobile>
                                            Access all downloadable content by Retweeting. We’ve noticed you’re visiting us on mobile, in order to download your instructions please visit us on desktop.
                                        </p>
                                        <a className="button" onClick={ this.openModal }>
                                            <img src={ iconTwitter } className="twitter" /> Tweet to Unlock
                                        </a>
                                    </div>
                                </div>
                            )
                            :
                            ''
                        */}
                    </div>
                </section>

                <section className="more">
                    <div className="container">
                        <header>
                            <h2>How do I get my own?</h2>
                            <p>Email us to create your own custom LEGO instructions and part list.</p>
                        </header>

                        <div className="guide">
                            <img src={ meekit } />
                            <a className="button" href="mailto:foreverapesnft@gmail.com" target="_blank">
                                Email us to get started
                            </a>
                        </div>
                    </div>
                </section>

                <section id="questions" className="questions">
                    <div className="container">
                        <header>
                            <h2>Questions?</h2>
                        </header>
                        <dl>
                            {/*
                            <dt onClick={ this.open }>
                                How do I get access to the instructions?
                            </dt>
                            <dd>
                                Easy peasy, just Tweet and verify above to unlock all the instructions and part lists. Free to download.
                            </dd>
                            */}
                            <dt onClick={ this.open }>
                                I’m in, but what about the LEGO bricks?
                            </dt>
                            <dd onClick={ this.open }>
                                Head over to the official <a href="https://www.lego.com/en-ca/pick-and-build/pick-a-brick">LEGO website</a> and add each piece from the part list to your cart. Done. Each of our instruction guides comes with a full parts list included.
                            </dd>
                            <dt onClick={ this.open }>
                                These look expensive, how much will this cost?
                            </dt>
                            <dd>
                                Each BrickHead parts kit will cost roughly $25 USD. They are approximately 8 cm / 3 inches tall.
                            </dd>
                            {/*
                            <dt onClick={ this.open }>
                                I LOVE these, can i do this to one of my own NFTs?
                            </dt>
                            <dd>
                               We can create a unique BrickHeadz for ALMOST any NFT. Check out our Shopify store to order your own build, we provide instruction guides, parts lists, and renders for $100 USD.
                            </dd>
                            */}
                            <dt onClick={ this.open }>
                                I went to LEGO.com but can't find a piece!
                            </dt>
                            <dd>
                                Dont stress! Occasionally our builds require unique third party pieces that can be found at <a href="https://www.bricklink.com" target="_blank">Brick Link</a>.
                            </dd>
                        </dl>
                    </div>
                </section>

                <footer>
                    <div className="container">
                        <p className="copy">
                            &copy; { new Date().getFullYear() } Forever Apes. All Rights Reserved
                        </p>
                        <div className="social">
                            <a href="https://twitter.com/ForeverApesNFT" target="_blank">
                                <img src={ iconTwitter } />
                            </a>
                            <a href="https://www.instagram.com/foreverapesnft/" target="_blank">
                                <img src={ iconInstagram } />
                            </a>
                            <a href="https://discord.gg/D858MnqTay" target="_blank">
                                <img src={ iconDiscord } />
                            </a>
                        </div>
                    </div>
                </footer>

                <Modal ariaHideApp={false} isOpen={ this.state.modal } onRequestClose={()=>{ this.closeModal() }}>
                    <a className="close" onClick={()=>{ this.closeModal() }}>x</a>
                    <div className="tweet">
                        <header>
                            <ol className="tweet-steps">
                                <li className={ this.state.step == '0' ? 'current' : '' } onClick={() => {

                                    setStep(0)

                                }}>1</li>
                                <li className={ this.state.step == '1' ? 'current' : '' } onClick={() => {

                                    if( this.state.step >= 1 )
                                        setStep(1)

                                }}>2</li>
                                <li className={ this.state.step == '2' ? 'current' : '' } onClick={() => {

                                    if( this.state.step >= 2 )
                                        setStep(2)

                                }}>3</li>
                            </ol>
                        </header>
                        <ol>
                            <li className={ this.state.step == '0' ? 'show' : 'hide' }>
                                <h2 className="head">
                                    <span>Step 1:</span>
                                    Enter your Twitter Handle
                                </h2>
                                <div className="input">
                                    <span onClick={()=>{ this.myInp.focus() }}>@</span>
                                    <input ref={(r) => this.myInp = r} type="handle" value={ this.state.handle } onChange={ this.handle } />
                                </div>
                                <div className="buttons">
                                    <a className="button" disabled={ !this.state.handle } onClick={() => {
                                        if( this.state.handle ){

                                            setStep(1)

                                        }
                                    }}>
                                        Next Step
                                    </a>
                                </div>
                            </li>
                            <li className={ this.state.step == '1' ? 'show' : 'hide' }>
                                <h2 className="head">
                                    <span>Step 2:</span>
                                    Send a Retweet
                                </h2>
                                <p className="note">Note: This must be posted exactly as provided. Any changes to the tweet will cause the validation to fail.</p>
                                <div className="buttons">
                                    <a className="button" target="_blank" href="https://twitter.com/intent/retweet?tweet_id=1709356948469199208" onClick={ () => setStep(2) }>
                                        <img src={ iconTwitter } className="twitter" /> Send Tweet
                                    </a>
                                </div>
                            </li>
                            <li className={ this.state.step == '2' ? 'show' : 'hide' }>
                                <h2 className="head">
                                    <span>Step 3:</span>
                                    Validate Submission
                                </h2>
                                <p className="note">Note: This validation may take 10 to 20 seconds.</p>
                                { this.state.error ? (
                                    <p class="error">{ this.state.error }</p>
                                ) : '' }
                                <div className="buttons">
                                    <a className="button" data-state={ this.state.validating } onClick={ this.validate }>{
                                        !this.state.validating ? (
                                            <>
                                                <img src={ iconTwitter } className="twitter" /> Validate Tweet
                                            </>
                                        ) : (
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ margin:'auto', background:'none', display:'block', shapeRendering:'auto', position: 'absolute', top:'-21px', left:'-14px', width:'100px' }} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                    <circle cx="50" cy="50" fill="none" stroke="#FFF" strokeWidth="3" r="6" strokeDasharray="28.274333882308138 11.42477796076938">
                                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                                    </circle>
                                                </svg>
                                                <span>Please Wait</span>
                                            </>
                                        )
                                    }
                                    </a>
                                </div>
                            </li>
                        </ol>
                    </div>
                </Modal>
            </>
        );
    }
    
}


export default withCookies( Build );