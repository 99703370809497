import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../redux/action'
import Main from './Main'
import { withRouter } from 'react-router';




function mapStateToProps(state, ownProps) {

  return {
      appMainStateData: {
          'posts':                      state.postsfun.posts,
          'walletAddress':              state.postsfun.walletAddress,
          'library':                    state.postsfun.library,
          'walletConnection':           state.postsfun.walletConnection,
          'apesFetched':                state.postsfun.apesFetched,
          'storyCount':                 state.postsfun.storyCount,
          'accept_submission':          state.postsfun.accept_submission,
          'show_count':                 state.postsfun.show_count,
          'submission_perwallet':       state.postsfun.submission_perwallet,
          'walletStoryCount':           state.postsfun.walletStoryCount,
          'fetchStoryCountWalletFlag':  state.postsfun.fetchStoryCountWalletFlag
      },
      comments: state.comments,
      riseMintData: {

        "whitelist": state.riseMint.whitelist,
        "mintCount": state.riseMint.mintCount,
        "transactionHash": state.riseMint.transactionHash,
        "txdetails":state.riseMint.txdetails,
        "err":state.riseMint.err,
        "saleState":state.riseMint.saleState

      }
  }
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const App = withRouter(connect(mapStateToProps, mapDispachToProps)(Main));

export default App;
