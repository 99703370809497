import React , { Component, useRef } from 'react';
import { instanceOf } from 'prop-types';
import parse from 'html-react-parser';
import {Link} from 'react-router-dom';
import Modal from 'react-modal';
import { LocomotiveScrollProvider, useLocomotiveScroll } from 'react-locomotive-scroll';
import { StickyContainer, Sticky } from 'react-sticky';
import Scroller from '../data/scroll.js';


import iconTwitter from '../img/Twitter-logo.svg';
import iconDiscord from '../img/home/icon-discord.svg';
import iconInstagram from '../img/home/icon-instagram.svg';
import txtHeadline from '../img/cards/headline.svg';
import iconApeCoin from '../img/cards/apecoin.svg';
import iconWave1Live from '../img/cards/waves/1live.svg';
import iconWave1Closed from '../img/cards/waves/1closed.svg';
import iconWave2Live from '../img/cards/waves/2live.svg';
import iconWave2Closed from '../img/cards/waves/2closed.svg';
import iconWave3Live from '../img/cards/waves/3live.svg';
import iconWave3Closed from '../img/cards/waves/3closed.svg';
import iconWave4Live from '../img/cards/waves/4live.svg';
import iconWave4Closed from '../img/cards/waves/4closed.svg';


import spade from '../img/cards/deck/spade.svg';


class Cards extends Component {


    /**
    *
    *   constructor
    *       - The Initialization Function
    *
    *   Params
    *       props:      (Object) The Properties passed to the component
    *
    **/
    constructor(props) {
        super(props);

        this.position   = 0;
        this.appId      = '1099fa44-d546-4eb0-8018-2559f2fdf6ca';

        this.state = {
            modal:          false,
            ready:          false,
            handle:         '',
            validating:     false,
            filter:         null,
            preloaded:      false,
            error:          null
        };

        this.authorize      = this.authorize.bind(this);
        this.closeModal     = this.closeModal.bind(this);
        this.openModal      = this.openModal.bind(this);
        this.validate       = this.validate.bind(this);

    }


    /**
    *
    *   componentDidMount
    *       -
    *
    *   Params
    *       n/a
    *
    **/
    componentDidMount(){

        const $script   = document.createElement('script');
        let obj         = this;
        $script.src     = 'https://cdn.tokenproof.xyz/js/tokenproof-oa-widget-v1.0.js';
        $script.id      = 'tokenProof'
        $script.async   = true;
        $script.onload  = $e => {

            //
            window.setTimeout(function(){

                obj.setState({
                    ready: true
                })

                //
                tokenproof.on('nonce', e => {

                    console.log( 'new nonce generated: ', e );

                });

                //
                tokenproof.on('verified', e => {
                    if( e.status == 'authenticated' ){

                        //Go to the claim page
                        window.location.href = 'https://store.foreverapes.io/en-ca/products/apecoin-card-deck';

                    }else{

                        //Failed
                        alert('You do not have enough $ape in your wallet to claim the apecoin card deck');

                    }
                });

            },50);

        }

        document.body.appendChild($script);

    }


    /**
    *
    *   componentWillUnmount
    *       -
    *
    *   Params
    *       n/a
    *
    **/
    componentWillUnmount(){

        //Remove Tokenproof
        document.getElementById('tokenProof').remove();

    }



    handleClick(e){
        //Do Nothing
    }

    /**
    *
    *   handle
    *       - Set the Handle State
    * 
    *   Params
    *       n/a
    * 
    **/
    authorize = async (e) => {
        var obj     = this;
        try {

            const result = await tokenproof.login({ appId: obj.appId });

            console.log()

        } catch (err) {

            console.log(err);

        }
    }

    /**
    *
    *   open
    *       - Toggle the Accordion
    *
    *   Params
    *       n/a
    *
    **/
    open = (e) => {
        e.currentTarget.classList.toggle('opened');
        Scroller.class.update();
    }




    /**
    *
    *   openModal
    *       - Opens the Modal
    *
    *   Params
    *       n/a
    *
    **/
    openModal = (e) => {
        this.setState({
            modal: true
        });
    }




    /**
    *
    *   closeModal
    *       - Closes the Modal
    *
    *   Params
    *       n/a
    *
    **/
    closeModal = (e) => {
        this.setState({
            modal: false
        });
    }




    /**
    *
    *   goTo
    *       - Manages the Scroll in Locomotive
    *
    *   Params:
    *       el          - (Element) The Element to interact with
    *       location:   - (Hash) The Hash URL to go to
    *
    **/
    goTo = ( el , location ) => {
        if( Scroller.class ){

            Scroller.class.scrollTo( location );

        }else{

            //Default Behaviour
            el.scrollIntoView({ behavior: 'smooth' });

        }
    }




    /**
    *
    *   submit
    *       - Submit the Form
    * 
    *   Params
    *       n/a
    * 
    **/
    validate = (e) => {

        const { cookies } = this.props;

        let obj     = this,
            message = 'Every day is leg day. Our winning Prop House proposal is now live! We suggest visiting on desktop or laptop.',
            url     = 'https://server.foreverapes.io/twitter/validate?handle=' + this.state.handle + '&locate=' + encodeURIComponent( message )

        this.setState({
            validating: true,
            error:      null
        });

        //Run the Request
        fetch(url).then( (response) => {
            return response.json();
        }).then( (response) => {

            obj.setState({
                validating: false,
                approved:   ( response.result )
            });

            cookies.set('builder', this.state.handle, { path: '/' });

            obj.setState({
                builder:    this.state.handle,
                available:  true,
                error:      ( !response.result ? 'We were unable to verify your retweet, please try again' : null )
            })

            if( response.result ){
                obj.closeModal();
            }

        })

    }








    /**
    *
    *   download
    *       - Download a File
    *
    *   Params
    *       n/a
    *
    **/
    download = (project) => {
        console.log(project);
        return;
    }




    
    /**
    *
    *   render
    *       - Render the Submit Function
    * 
    *   Params
    *       n/a
    * 
    **/
    render(){

        var obj = this;

        var setStep = (step) => {
            this.setState({
                step: step
            })
        };

        let stepClass = ( this.state.approved ? 'active' : null );

        //
        return (
            <>
                {/*
                <div id="preloader" data-preloaded={ this.state.preloaded }>

                    <div className="innerBox"></div>

                    {/* Club }
                    <svg version="1.1" className="club" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21.6 21.9" style={{ 'enableBackground': 'new 0 0 21.6 21.9' }} xmlSpace="preserve">
                        <g>
                            <path d="M18.8,7.6h-1.7c-0.2,0-0.4,0.2-0.4,0.4v1.3c0,0.2,0,0.4-0.2,0.4h-2.2c-0.2,0-0.4-0.2-0.4-0.4v-2
                                c0-0.2,0.2-0.5,0.4-0.5h1.7c0.2,0,0.4-0.2,0.4-0.4V4.7V2.6c0-0.2-0.2-0.4-0.4-0.4h-1.7c-0.2,0-0.4-0.2-0.4-0.4V0.4
                                C14,0.2,13.8,0,13.6,0H12h-2H7.9C7.8,0,7.6,0.2,7.6,0.4v1.6c0,0.2-0.2,0.4-0.4,0.4H5.5c-0.2,0-0.4,0.2-0.4,0.4v2.1v1.6
                                c0,0.2,0.2,0.4,0.4,0.4h1.7c0.2,0,0.4,0.3,0.4,0.5v2c0,0.2-0.2,0.4-0.4,0.4H5.1c-0.2,0-0.2-0.2-0.2-0.4V8c0-0.2-0.2-0.4-0.4-0.4
                                H2.8C2.6,7.6,2.4,7.8,2.4,8v1.3c0,0.2-0.2,0.4-0.4,0.4H0.4C0.2,9.6,0,9.7,0,9.9V12v1.8c0,0.2,0.2,0.4,0.4,0.4h1.7
                                c0.2,0,0.4,0.2,0.4,0.4v1.4c0,0.2,0.2,0.4,0.4,0.4h2.1h1.9c0.2,0,0.4-0.2,0.4-0.4v-1.4c0-0.2,0.2-0.4,0.4-0.4h1.7
                                c0.2,0,0.4,0.2,0.4,0.4v2c0,0.2-0.2,0.5-0.4,0.5H7.5c-0.2,0-0.4,0.2-0.4,0.4v1.7c0,0.2-0.2,0.4-0.4,0.4H5.1c-0.2,0-0.4,0.2-0.4,0.4
                                v1.7c0,0.2,0.2,0.4,0.4,0.4h2.1h2.4H12h2.4h2.1c0.2,0,0.4-0.2,0.4-0.4v-1.7c0-0.2-0.2-0.4-0.4-0.4h-1.7c-0.2,0-0.4-0.2-0.4-0.4
                                v-1.7c0-0.2-0.2-0.4-0.4-0.4h-1.7c-0.2,0-0.4-0.3-0.4-0.5v-2c0-0.2,0.2-0.4,0.4-0.4h1.7c0.2,0,0.4,0.2,0.4,0.4v1.4
                                c0,0.2,0.2,0.4,0.4,0.4h1.9h2.1c0.2,0,0.4-0.2,0.4-0.4v-1.4c0-0.2,0.2-0.4,0.4-0.4h1.7c0.2,0,0.4-0.2,0.4-0.4V12V9.9
                                c0-0.2-0.2-0.4-0.4-0.4h-1.7c-0.2,0-0.4-0.2-0.4-0.4V8C19.1,7.8,19,7.6,18.8,7.6z"/>
                        </g>
                    </svg>

                    {/* Diamond }
                    <svg version="1.1" className="diamond" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 17.3 20.9" style={{ 'enableBackground': 'new 0 0 17.3 20.9' }} xmlSpace="preserve">
                        <g>
                            <path d="M14,6.3V4.1c0-0.4-0.3-0.6-0.6-0.6h-2c-0.4,0-0.6-0.3-0.6-0.6V0.6c0-0.4-0.3-0.6-0.6-0.6H7.2C6.8,0,6.5,0.3,6.5,0.6v2.2
                                c0,0.4-0.3,0.6-0.6,0.6h-2c-0.4,0-0.6,0.3-0.6,0.6v2.2c0,0.4-0.3,0.6-0.6,0.6h-2C0.3,6.9,0,7.2,0,7.6v5.1c0,0.4,0.3,0.6,0.6,0.6h2
                                c0.4,0,0.6,0.3,0.6,0.6v2.9c0,0.4,0.3,0.6,0.6,0.6h2c0.4,0,0.6,0.3,0.6,0.6v2.2c0,0.4,0.3,0.6,0.6,0.6h2.9c0.4,0,0.6-0.3,0.6-0.6
                                v-2.2c0-0.4,0.3-0.6,0.6-0.6h2c0.4,0,0.6-0.3,0.6-0.6v-2.9c0-0.4,0.3-0.6,0.6-0.6h2c0.4,0,0.6-0.3,0.6-0.6V7.6
                                c0-0.4-0.3-0.6-0.6-0.6h-2C14.3,6.9,14,6.6,14,6.3z"/>
                        </g>
                    </svg>

                    {/* Heart }
                    <svg version="1.1" className="heart" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 19.5 20.9" style={{ 'enableBackground': 'new 0 0 19.5 20.9' }} xmlSpace="preserve">
                        <g>
                            <path d="M19.5,6.3V4c0-0.3-0.2-0.6-0.6-0.6h-1.8c-0.3,0-0.6-0.2-0.6-0.6V0.6c0-0.3-0.2-0.6-0.6-0.6h-0.8h-2.9h-0.8
                                c-0.3,0-0.6,0.2-0.6,0.6v2.3c0,0.3-0.2,0.6-0.6,0.6H9c-0.3,0-0.6-0.2-0.6-0.6V0.6C8.5,0.2,8.2,0,7.9,0H7.1H4.2H3.4
                                C3.1,0,2.9,0.2,2.9,0.6v2.3c0,0.3-0.2,0.6-0.6,0.6l0,0H0.6C0.2,3.5,0,3.7,0,4v3.7l0,0v2.8v2.9C0,13.7,0.3,14,0.6,14h0.6h2.7
                                c0.3,0,0.6,0.2,0.6,0.6v2.3c0,0.3,0.2,0.6,0.6,0.6h2.1c0.3,0,0.6,0.2,0.6,0.6v2.3C7.8,20.7,8,21,8.4,21h3.1c0.3,0,0.6-0.2,0.6-0.6
                                V18c0-0.3,0.2-0.6,0.6-0.6h2.1c0.3,0,0.6-0.2,0.6-0.6v-2.3c0-0.3,0.2-0.6,0.6-0.6h2.7l0,0H19c0.3,0,0.6-0.2,0.6-0.6v-2.9L19.5,6.3
                                L19.5,6.3z"/>
                        </g>
                    </svg>

                    {/* Spade }
                    <svg version="1.1" className="spade" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 17.3 20.9" style={{ 'enableBackground': 'new 0 0 17.3 20.9' }} xmlSpace="preserve">
                        <g>
                            <path d="M16.6,6.9h-2c-0.4,0-0.6-0.3-0.6-0.6V4.1c0-0.4-0.3-0.6-0.6-0.6h-2c-0.4,0-0.6-0.3-0.6-0.6V0.6
                                c0-0.4-0.3-0.6-0.6-0.6H7.2C6.8,0,6.5,0.3,6.5,0.6v2.2c0,0.4-0.3,0.6-0.6,0.6h-2c-0.4,0-0.6,0.3-0.6,0.6v2.2c0,0.4-0.3,0.6-0.6,0.6
                                h-2C0.3,6.9,0,7.2,0,7.6v2.8h0V14c0,0.4,0.3,0.6,0.6,0.6h2.5h1.1h1.6c0.4,0,0.6,0.3,0.6,0.6V16c0,0.4-0.3,0.6-0.6,0.6H2.9
                                c-0.4,0-0.6,0.3-0.6,0.6v2.9c0,0.4,0.3,0.6,0.6,0.6h3.6h4.2h3.6c0.4,0,0.6-0.3,0.6-0.6v-2.9c0-0.4-0.3-0.6-0.6-0.6h-2.9
                                c-0.4,0-0.6-0.3-0.6-0.6v-0.8c0-0.4,0.3-0.6,0.6-0.6H13h1.1h2.5c0.4,0,0.6-0.3,0.6-0.6v-2.8h0V7.6C17.3,7.2,17,6.9,16.6,6.9z"/>
                        </g>
                    </svg>

                    {/* Shuffling }
                    <svg version="1.1" className="title" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 193.8 55.6" style={{ 'enableBackground': 'new 0 0 193.8 55.6' }} xmlSpace="preserve">
                        <g>
                            <path d="M11.6,55.6c-4.1,0-7.1-1.3-8.9-3.8C0.9,49.2,0,43.9,0,35.9h10.7c0,6.6,0,10.2,0,10.8c0,0.6,0.3,0.9,0.9,0.9
                                c0.5,0,0.8-0.3,0.8-0.9c0-0.6,0-2.4,0-5.4c0-2.9-0.1-4.9-0.4-6c-0.2-1.1-1.3-2.1-3-3.2C4.7,30,2.1,27.9,1.3,26
                                C0.5,24,0,20.9,0,16.6C0,10.7,1,6.5,2.9,3.9C4.8,1.3,7.7,0,11.6,0c3.8,0,6.7,1.4,8.6,4.2c1.9,2.8,2.9,7.9,2.9,15.3H12.4
                                c0-6.4,0-9.9,0-10.6c0-0.7-0.3-1-0.8-1c-0.5,0-0.8,0.3-0.8,1c0,0.7,0,2.4,0,5.1c0,3.2,0.1,5.4,0.4,6.3c0.3,1,1.4,2,3.4,3.1
                                c4,2.2,6.4,4.1,7.3,5.8c0.9,1.7,1.3,4.8,1.3,9.3c0,5.9-0.9,10.1-2.7,12.8C18.7,54.2,15.8,55.6,11.6,55.6z"/>
                            <path d="M24.6,0.4h10.7v23.2h1.5V0.4h10.7v54.8H36.8V31.6h-1.5v23.6H24.6V0.4z"/>
                            <path d="M60.5,55.6c-4.2,0-7.2-1.1-8.9-3.2c-1.7-2.1-2.6-5-2.6-8.6V0.4h10.7v43.6c0,2,0,3.1,0.1,3.3c0.1,0.2,0.3,0.3,0.8,0.3
                                c0.4,0,0.6-0.1,0.7-0.3c0.1-0.2,0.1-1.3,0.1-3.3V0.4H72v43.3c0,3.6-0.9,6.5-2.6,8.6C67.7,54.5,64.7,55.6,60.5,55.6z"/>
                            <path d="M73.5,0.4h15.3v9.9h-4.5v12.1h4.5v10h-4.5v22.8H73.5V0.4z"/>
                            <path d="M90.2,0.4h15.3v9.9H101v12.1h4.5v10H101v22.8H90.2V0.4z"/>
                            <path d="M114.7,44.3v0.9h7.9v10h-15.7V0.4h10.8v41.9c0,0.5-0.2,1-0.5,1.3c-0.3,0.4-0.8,0.5-1.3,0.5L114.7,44.3z"/>
                            <path d="M123.8,0.4h10.9v54.8h-10.9V0.4z"/>
                            <path d="M146.8,55.2h-10.6V0.4h20l1.9,46.5h1l-0.5-20.8c0-0.3,0-0.6,0-1s0-0.7,0-1V0.4h10.7v54.8H149L147,8.4h-1l0.7,20.4
                                c0,0.3,0,0.6,0,1c0,0.4,0,0.7,0,1V55.2z"/>
                            <path d="M180.5,55.5c-2.9,0-5.2-1.3-7-3.9c-1.8-2.6-2.7-10.6-2.7-24c0-12.7,0.8-20.5,2.5-23.3c1.7-2.8,4.7-4.3,9-4.3
                                c4.3,0,7.3,1.5,9,4.4s2.5,8.5,2.5,16.6h-10.7c0-7.2,0-11.2,0-11.9c0-0.7-0.3-1.1-0.8-1.1c-0.6,0-0.9,0.3-0.9,0.9
                                c0,0.6,0,7.2,0,19.6c0,9.3,0,14.8,0.1,16.5c0,1.7,0.5,2.6,1.5,2.6c0.7,0,1.1-0.4,1.3-1.3c0.1-0.9,0.2-3.4,0.2-7.6l0.3-3.3h-2.4
                                v-7.3h11.4v27.3h-6.3l0-3.1l0.7-3l-0.9-0.2l-0.8,3.2C185.8,54.4,183.8,55.5,180.5,55.5z"/>
                        </g>
                    </svg>


                </div>
                */ }


                {/* Preloader */}
                <svg id="preloader" data-preloaded={ this.state.preloaded } version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 1280" style={{ 'enableBackground': 'new 0 0 1920 1280' }} xmlSpace="preserve" preserveAspectRatio="xMidYMid slice">

                    {/* */}
                    <defs>
                        <mask id="clip" maskUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="1920" height="1280" fill="white" />
                            <path d="M1153.7,556.4h-48.5c-9.7,0-14.5-7.3-14.5-14.5v-53.3c0-9.7-7.3-14.5-14.5-14.5h-48.5
                                c-9.7,0-14.5-7.3-14.5-14.5v-55.8c0-9.7-7.3-14.5-14.5-14.5h-72.7c-9.7,0-17,7.3-17,14.5V457c0,9.7-7.3,14.5-14.5,14.5h-48.5
                                c-9.7,0-14.5,7.3-14.5,14.5v53.3c0,9.7-7.3,14.5-14.5,14.5h-48.5c-9.7,2.4-17,9.7-17,19.4v67.9l0,0v87.3c0,9.7,7.3,14.5,14.5,14.5
                                h60.6h26.7h38.8c9.7,0,14.5,7.3,14.5,14.5V777c0,9.7-7.3,14.5-14.5,14.5h-70.3c-9.7,0-14.5,7.3-14.5,14.5v70.3
                                c0,9.7,7.3,14.5,14.5,14.5h87.3h101.8h87.3c9.7,0,14.5-7.3,14.5-14.5V806c0-9.7-7.3-14.5-14.5-14.5h-70.3
                                c-9.7,0-14.5-7.3-14.5-14.5v-19.4c0-9.7,7.3-14.5,14.5-14.5h38.8h26.7h60.6c9.7,0,14.5-7.3,14.5-14.5v-67.9l0,0v-87.3
                                C1170.6,563.6,1163.4,556.4,1153.7,556.4z" fill="black" />
                        </mask>
                    </defs>

                    {/* Background */}
                    <g id="BG">
                        <rect x="0" y="0" className="bg" width="1920" height="1280" />
                    </g>

                    <g id="Spade" className="deck" style={{ animationDelay: '0.5s' }}>
                        <g>
                            <path className="card" d="M1153.7,556.4h-48.5c-9.7,0-14.5-7.3-14.5-14.5v-53.3c0-9.7-7.3-14.5-14.5-14.5h-48.5
                                c-9.7,0-14.5-7.3-14.5-14.5v-55.8c0-9.7-7.3-14.5-14.5-14.5h-72.7c-9.7,0-17,7.3-17,14.5V457c0,9.7-7.3,14.5-14.5,14.5h-48.5
                                c-9.7,0-14.5,7.3-14.5,14.5v53.3c0,9.7-7.3,14.5-14.5,14.5h-48.5c-9.7,2.4-17,9.7-17,19.4v67.9l0,0v87.3c0,9.7,7.3,14.5,14.5,14.5
                                h60.6h26.7h38.8c9.7,0,14.5,7.3,14.5,14.5V777c0,9.7-7.3,14.5-14.5,14.5h-70.3c-9.7,0-14.5,7.3-14.5,14.5v70.3
                                c0,9.7,7.3,14.5,14.5,14.5h87.3h101.8h87.3c9.7,0,14.5-7.3,14.5-14.5V806c0-9.7-7.3-14.5-14.5-14.5h-70.3
                                c-9.7,0-14.5-7.3-14.5-14.5v-19.4c0-9.7,7.3-14.5,14.5-14.5h38.8h26.7h60.6c9.7,0,14.5-7.3,14.5-14.5v-67.9l0,0v-87.3
                                C1170.6,563.6,1163.4,556.4,1153.7,556.4z"/>
                        </g>
                    </g>

                    {/* Club */}
                    <g id="Club" className="deck" style={{ animationDelay: '1s' }}>
                        <g>
                            <path className="card" d="M1152.7,556.4h-41.2c-4.8,0-9.7,4.8-9.7,9.7v31.5c0,4.8,0,9.7-4.8,9.7h-53.3c-4.8,0-9.7-4.8-9.7-9.7v-48.5
                                c0-4.8,4.8-12.1,9.7-12.1h41.2c4.8,0,9.7-4.8,9.7-9.7v-41.2v-50.9c0-4.8-4.8-9.7-9.7-9.7h-41.2c-4.8,0-9.7-4.8-9.7-9.7v-33.9
                                c2.4-4.8-2.4-9.7-7.3-9.7h-38.8h-48.5h-50.9c-2.4,0-7.3,4.8-7.3,9.7v38.8c0,4.8-4.8,9.7-9.7,9.7h-41.2c-4.8,0-9.7,4.8-9.7,9.7
                                v50.9v38.8c0,4.8,4.8,9.7,9.7,9.7h41.2c4.8,0,9.7,7.3,9.7,12.1V600c0,4.8-4.8,9.7-9.7,9.7h-50.9c-4.8,0-4.8-4.8-4.8-9.7v-33.9
                                c0-4.8-4.8-9.7-9.7-9.7h-41.2c-4.8,0-9.7,4.8-9.7,9.7v31.5c0,4.8-4.8,9.7-9.7,9.7h-38.8c-4.8-2.4-9.7,0-9.7,4.8V663v43.6
                                c0,4.8,4.8,9.7,9.7,9.7h41.2c4.8,0,9.7,4.8,9.7,9.7V760c0,4.8,4.8,9.7,9.7,9.7h50.9h46.1c4.8,0,9.7-4.8,9.7-9.7v-33.9
                                c0-4.8,4.8-9.7,9.7-9.7h41.2c4.8,0,9.7,4.8,9.7,9.7v48.5c0,4.8-4.8,12.1-9.7,12.1h-46.1c-4.8,0-9.7,4.8-9.7,9.7v41.2
                                c0,4.8-4.8,9.7-9.7,9.7h-38.8c-4.8,0-9.7,4.8-9.7,9.7v41.2c0,4.8,4.8,9.7,9.7,9.7h50.9h58.2h58.2h58.2h50.9c4.8,0,9.7-4.8,9.7-9.7
                                v-41.2c0-4.8-4.8-9.7-9.7-9.7h-41.2c-4.8,0-9.7-4.8-9.7-9.7v-41.2c0-4.8-4.8-9.7-9.7-9.7h-41.2c-4.8,0-9.7-7.3-9.7-12.1v-48.5
                                c0-4.8,4.8-9.7,9.7-9.7h41.2c4.8,0,9.7,4.8,9.7,9.7V760c0,4.8,4.8,9.7,9.7,9.7h46.1h50.9c4.8,0,9.7-4.8,9.7-9.7v-33.9
                                c0-4.8,4.8-9.7,9.7-9.7h41.2c4.8,0,9.7-4.8,9.7-9.7V663v-50.9c0-4.8-4.8-9.7-9.7-9.7h-41.2c-4.8,0-9.7-4.8-9.7-9.7v-26.7
                                C1160,561.2,1157.6,556.4,1152.7,556.4z"/>
                        </g>
                    </g>

                    {/* Diamond */}
                    <g id="Diamond" className="deck" style={{ animationDelay: '1.5s' }}>
                        <g>
                            <path className="card" d="M1094.5,540.6v-53.3c0-9.7-7.3-14.5-14.5-14.5h-48.5c-9.7,0-14.5-7.3-14.5-14.5v-55.8
                                c0-9.7-7.3-14.5-14.5-14.5h-72.7c-9.7,0-17,7.3-17,14.5v53.3c0,9.7-7.3,14.5-14.5,14.5h-48.5c-9.7,0-14.5,7.3-14.5,14.5v53.3
                                c0,9.7-7.3,14.5-14.5,14.5h-48.5c-9.7,2.4-17,9.7-17,19.4v123.6c0,9.7,7.3,14.5,14.5,14.5h48.5c9.7,0,14.5,7.3,14.5,14.5v70.3
                                c0,9.7,7.3,14.5,14.5,14.5h48.5c9.7,0,14.5,7.3,14.5,14.5v53.3c0,9.7,7.3,14.5,14.5,14.5h70.3c9.7,0,14.5-7.3,14.5-14.5v-53.3
                                c0-9.7,7.3-14.5,14.5-14.5h48.5c9.7,0,14.5-7.3,14.5-14.5v-70.3c0-9.7,7.3-14.5,14.5-14.5h48.5c9.7,0,14.5-7.3,14.5-14.5V572.1
                                c0-9.7-7.3-14.5-14.5-14.5h-48.5C1101.8,555.2,1094.5,547.9,1094.5,540.6z"/>
                        </g>
                    </g>

                    {/* Heart */}
                    <g id="Heart" className="deck" style={{ animationDelay: '2s' }}>
                        <g>
                            <path className="card" d="M1195.1,538.2v-55.8c0-7.3-4.8-14.5-14.5-14.5h-43.6c-7.3,0-14.5-4.8-14.5-14.5V400c0-7.3-4.8-14.5-14.5-14.5
                                h-19.4h-70.3h-19.4c-7.3,0-14.5,4.8-14.5,14.5v55.8c0,7.3-4.8,14.5-14.5,14.5h-29.1c-7.3,0-14.5-4.8-14.5-14.5V400
                                c2.4-9.7-4.8-14.5-12.1-14.5h-19.4h-70.3h-19.4c-7.3,0-12.1,4.8-12.1,14.5v55.8c0,7.3-4.8,14.5-14.5,14.5l0,0H737
                                c-9.7,0-14.5,4.8-14.5,12.1v89.7l0,0V640v70.3c0,7.3,7.3,14.5,14.5,14.5h14.5H817c7.3,0,14.5,4.8,14.5,14.5v55.8
                                c0,7.3,4.8,14.5,14.5,14.5H897c7.3,0,14.5,4.8,14.5,14.5V880c0,7.3,4.8,14.5,14.5,14.5h75.1c7.3,0,14.5-4.8,14.5-14.5v-58.2
                                c0-7.3,4.8-14.5,14.5-14.5h50.9c7.3,0,14.5-4.8,14.5-14.5V737c0-7.3,4.8-14.5,14.5-14.5h65.4l0,0h7.3c7.3,0,14.5-4.8,14.5-14.5
                                v-70.3L1195.1,538.2L1195.1,538.2z"/>
                        </g>
                    </g>



                    {/* Shuffling */}
                    <g id="Shuffling" className="title">
                        <g>
                            <path className="text" d="M702.1,724.1c-12.4,0-21.5-3.9-26.9-11.5c-5.4-7.9-8.2-23.9-8.2-48.1h32.4c0,20,0,30.8,0,32.7
                                c0,1.8,0.9,2.7,2.7,2.7c1.5,0,2.4-0.9,2.4-2.7c0-1.8,0-7.3,0-16.3c0-8.8-0.3-14.8-1.2-18.1c-0.6-3.3-3.9-6.3-9.1-9.7
                                c-13-6.3-20.9-12.7-23.3-18.4c-2.4-6-3.9-15.4-3.9-28.4c0-17.8,3-30.5,8.8-38.4c5.7-7.9,14.5-11.8,26.3-11.8
                                c11.5,0,20.3,4.2,26,12.7c5.7,8.5,8.8,23.9,8.8,46.3h-32.4c0-19.4,0-29.9,0-32.1c0-2.1-0.9-3-2.4-3c-1.5,0-2.4,0.9-2.4,3
                                c0,2.1,0,7.3,0,15.4c0,9.7,0.3,16.3,1.2,19c0.9,3,4.2,6,10.3,9.4c12.1,6.7,19.4,12.4,22.1,17.5c2.7,5.1,3.9,14.5,3.9,28.1
                                c0,17.8-2.7,30.5-8.2,38.7C723.5,719.8,714.8,724.1,702.1,724.1z"/>
                            <path className="text" d="M741.4,557.1h32.4v70.2h4.5v-70.2h32.4v165.7h-32.4v-71.4h-4.5v71.4h-32.4V557.1z"/>
                            <path className="text" d="M849.9,724.1c-12.7,0-21.8-3.3-26.9-9.7c-5.1-6.3-7.9-15.1-7.9-26V557.1h32.4V689c0,6,0,9.4,0.3,10
                                s0.9,0.9,2.4,0.9c1.2,0,1.8-0.3,2.1-0.9s0.3-3.9,0.3-10V557.1h32.1v130.9c0,10.9-2.7,19.7-7.9,26
                                C871.7,720.7,862.6,724.1,849.9,724.1z"/>
                            <path className="text" d="M889.2,557.1h46.3v29.9h-13.6v36.6h13.6v30.2h-13.6v68.9h-32.7V557.1z"/>
                            <path className="text" d="M939.7,557.1H986v29.9h-13.6v36.6H986v30.2h-13.6v68.9h-32.7V557.1z"/>
                            <path className="text" d="M1013.8,689.9v2.7h23.9v30.2h-47.5V557.1h32.7v126.7c0,1.5-0.6,3-1.5,3.9c-0.9,1.2-2.4,1.5-3.9,1.5
                                L1013.8,689.9z"/>
                            <path className="text" d="M1041.3,557.1h33v165.7h-33C1041.3,722.9,1041.3,557.1,1041.3,557.1z"/>
                            <path className="text" d="M1110.9,722.9h-32.1V557.1h60.5l5.7,140.6h3l-1.5-62.9c0-0.9,0-1.8,0-3c0-1.2,0-2.1,0-3v-71.7h32.4v165.7
                                h-61.4l-6-141.5h-3l2.1,61.7c0,0.9,0,1.8,0,3c0,1.2,0,2.1,0,3v73.8H1110.9z"/>
                            <path className="text" d="M1212.8,723.8c-8.8,0-15.7-3.9-21.2-11.8c-5.4-7.9-8.2-32.1-8.2-72.6c0-38.4,2.4-62,7.6-70.5
                                c5.1-8.5,14.2-13,27.2-13s22.1,4.5,27.2,13.3c5.1,8.8,7.6,25.7,7.6,50.2h-32.4c0-21.8,0-33.9,0-36c0-2.1-0.9-3.3-2.4-3.3
                                c-1.8,0-2.7,0.9-2.7,2.7c0,1.8,0,21.8,0,59.3c0,28.1,0,44.8,0.3,49.9c0,5.1,1.5,7.9,4.5,7.9c2.1,0,3.3-1.2,3.9-3.9
                                c0.3-2.7,0.6-10.3,0.6-23l0.9-10h-7.3v-22.1h34.5v82.5h-19v-9.4l2.1-9.1l-2.7-0.6l-2.4,9.7
                                C1228.8,720.4,1222.8,723.8,1212.8,723.8z"/>
                        </g>

                    </g>
                </svg>

                {/* Header */}
                <nav className="navigation" data-scroll data-scroll-sticky data-scroll-target="#root > div > main">
                    <div className="hello-bar">
                        <p>
                            In order to claim, simply authenticate a wallet with 50+ $APE
                        </p>
                    </div>
                    <div className={`navigation__container ${ this.state.approved ? 'approved' : '' }`}>

                        {/* <!-- Logo --> */}
                        <div className="navigation__logo">
                            <a className="navigation__logo-link" href="/">
                                FOREVER APES
                            </a>                
                        </div>
                        {/* <!-- END Logo --> */}

                        {/* <!-- Mobile menu --> */}
                            <div className="coin">
                                <p>Powered By</p>
                                <img src={ iconApeCoin } />
                            </div>
                        {/* <!-- END Mobile menu --> */} 

                    </div> 
                </nav>

                { /* this.state.submitted ? <Success /> : this.form() */ }

                <section className="header">
                    <div className="video">
                        <iframe src={ 'https://player.vimeo.com/video/797763178?h=74c39ade4f&badge=0&autopause=0&autoplay=1&loop=1&player_id=0&background=1&app_id=58479/embed' } allow="autoplay" frameBorder="0" onLoad={()=>{
                            window.setTimeout(()=>{
                                obj.setState({
                                    preloaded: true
                                });
                            },3000);
                        }}></iframe>
                    </div>
                    <div>
                        <div>
                            <div>
                                <div className="steps">
                                    <img src={ iconWave4Closed } />
                                </div>
                                <h1>
                                    <img src={ txtHeadline } />
                                </h1>
                                <p>Authenticated with tokenproof, this free-to-claim deck requires that you have 50+ ApeCoin in your wallet. No strings attached.</p>
                            </div>
                            <div>
                                {/*
                                <button id="tokenBtn" onClick={ this.authorize } style={{ visibility: ( this.state.ready ? 'visible' : 'hidden' ) }}>
                                    <img src={ 'https://cdn.tokenproof.xyz/img/tokenproofIconBlue.png' } style={{ height: '30px' }} /> <span>Authenticate with tokenproof</span>
                                </button>
                                */}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
    
}


export default Cards;