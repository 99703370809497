

const riseMintData = {
    "saleState":null,
    "whitelist": null,
    "mintCount":null,
    "transactionHash":null,
    "txdetails":null,
    "err":null

}

export default riseMintData;

