import React from "react";
import { Component } from 'react'
import loadingsvg from "../img/loading.svg"
// import fail from "../img/fail.svg"
// import passsvg from "../img/check.svg"
import loading from '../img/loading.svg';
import error from '../img/error.svg';
import check from '../img/check.svg';

class Rise extends Component {

    constructor(props) {
        super(props);
        this.state = {
            claimButton: true,
            transactionStatus: '',
            mintQuantity: 1,
            price:0.07,
            whitelist: true
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        console.log(prevProps);

        if (prevProps.appMainStateData.walletConnection !== this.props.appMainStateData.walletConnection && this.props.appMainStateData.walletConnection == true) {
            // console.log("component updated!");
            // console.log(prevProps);
           this.props.addWhitelistEligibility(this.props.appMainStateData.walletAddress, this.props.appMainStateData.library)
           this.props.RiseCount(this.props.appMainStateData.walletAddress, this.props.appMainStateData.library)

        }

        if (prevProps.appMainStateData.walletAddress !== this.props.appMainStateData.walletAddress && this.props.appMainStateData.walletConnection == true) {
            console.log("component updated!");
            console.log(prevProps);
           this.props.addWhitelistEligibility(this.props.appMainStateData.walletAddress, this.props.appMainStateData.library)
           this.props.RiseCount(this.props.appMainStateData.walletAddress, this.props.appMainStateData.library)
        }


    }

    getWhiteList() {
        this.props.addWhitelistEligibility(this.props.appMainStateData.walletAddress, this.props.appMainStateData.library)
    }

    handleQuanityChange = param => e => {
        // param is the argument you passed to the function
        // e is the event object that returned

        let currentMintQuanity = this.state.mintQuantity;

        if (param === "plus") {
            if (currentMintQuanity == 20){
                this.setState({ 'price': 1.4})
                return;
            }
            this.setState({ 'mintQuantity': currentMintQuanity + 1 })
            this.setState({ 'price': (0.07*(currentMintQuanity + 1)).toFixed(2) })
        }
        if (param === "minus" ) {
            if (currentMintQuanity == 1){
                this.setState({ 'price': 0.07})
                return;
            }
               
            this.setState({ 'mintQuantity': currentMintQuanity - 1 })
            this.setState({ 'price': (0.07*(currentMintQuanity - 1)).toFixed(2) })
        }
    };

    setMax = param => e => {
        // param is the argument you passed to the function
        // e is the event object that returned

        this.setState({ 'mintQuantity': 20})
            this.setState({ 'price': 1.4 })
    };


    handleMint = event => {
        event.preventDefault();
   //    this.props.clearTransaction();
        this.props.MintRise(this.props.appMainStateData.library,this.state.mintQuantity);
        // this.setState({ claimButton: false });
    };

    render() {
        console.log(this.props.riseMintData)
        const { match, appMainStateData } = this.props
        const id = match.params.id
        var count = /*5000 - */this.props.riseMintData.mintCount;
        let walletNotConnectedORNoApes = false;
        let showApe = false;
        let message = "";

        if (this.props.appMainStateData.walletConnection === null) {
            console.log("loading");
            const loading = true;
        }
        if (this.props.appMainStateData.walletConnection == false) {
            // console.log("Wallet not connected");
            walletNotConnectedORNoApes = true;
            message = "Please connect your wallet to mint";
        }

        if (walletNotConnectedORNoApes) {
            return <div>

                <h2 style={{ 'marginTop': '10px' }} className="header-home__title-2">{message}</h2>
            </div>
        }

        if (this.props.loading === true || this.props.riseMintData.whitelist === null || this.props.riseMintData.saleState ===null) {

            return <section className="">
                {/* <div className="loader"> ...loading </div> */}
            </section>
        } else if (true) {

            return <div>
                <div className="header-details__number-wrp">
                    <div>
                        <p className="header-details__text-number">{count}{/*<span> /5000</span>*/}</p>
                        <h2 className="header-details__title-2">Tokens Minted</h2>
                    </div>
                    <span className="header-details__price">
                        <span>{this.state.price}</span>
                        <span className="currency">ETH</span>
                    </span>
                </div>


                {/* <!-- END Text & Title -->

    <!-- BTNs --> */}
                <div className="header-details__btn-wrp">
                    <div className="header-details__quantity-wrp">
                        <div className="header-details__quantity">
                            <span className="minus" onClick={this.handleQuanityChange("minus")}>-</span>
                            <input type="text" value={this.state.mintQuantity}></input>
                            <span className="plus" onClick={this.handleQuanityChange("plus")}>+</span>
                        </div>
                        <div className="header-details__quantity-max" onClick={this.setMax("plus")}>
                            <span>MAX</span>
                        </div>
                    </div>
                    {/* <a className="btn-brown-border-grayed">
                        MINT RISE TOKEN (SOON)
                    </a> */}
                    {(this.props.riseMintData.whitelist === 0&& this.props.riseMintData.saleState===1) && <div className="header-home__btn-wrp">
                    <a className="btn-brown-border-grayed" >
                        MINT
                    </a>
                </div>}
                {(this.props.riseMintData.saleState===0) && <div className="header-home__btn-wrp">
                    <a className="btn-brown-border-grayed" >
                        MINT
                    </a>
                </div>}
                {this.props.riseMintData.saleState===1 && this.props.riseMintData.whitelist !== 0 && <div className="header-home__btn-wrp">
                    <a className="btn-brown" onClick={this.handleMint}>
                    MINT
                    </a>
                </div>}
                {this.props.riseMintData.saleState===2 && <div className="header-home__btn-wrp">
                    <a className="btn-brown" onClick={this.handleMint}>
                    MINT
                    </a>
                </div>}
                </div>
                {this.props.riseMintData.saleState===1 && this.props.riseMintData.whitelist === 0 && <p className="header-details__text">Please wait for public mint</p>}
                {this.props.riseMintData.saleState===1 && this.props.riseMintData.whitelist !== 0 && <p className="header-details__text">Whitelist {this.props.riseMintData.whitelist} available to mint  </p>}
                {/*
    <!-- END BTNs -->
    <!-- ACTIONS 
    */}
                {this.props.riseMintData.transactionHash !== null && this.props.riseMintData.transactionHash !== undefined && <div className="header-details__actions">
                {this.props.riseMintData.err && this.props.riseMintData.err.error && this.props.riseMintData.err.message &&  <div data-error>
                        <p>{this.props.riseMintData.err.error.message}</p>
                    </div>}
                    {this.props.riseMintData.err && <div data-fail>
                        <p><img src={error} alt="Error" /> Transaction Error!</p>
                        <a href={"https://etherscan.io/tx/" + this.props.riseMintData.transactionHash} target="_blank" >Hash: {this.props.riseMintData.transactionHash.substring(0, 6)}...{this.props.riseMintData.transactionHash.substring(this.props.riseMintData.transactionHash.length - 4)}</a>
                    </div>}
                    {this.props.riseMintData.txdetails  && !this.props.riseMintData.err && this.props.riseMintData.txdetails.status === 1 && <div data-success>
                        <p><img src={check} alt="Success" /> Transaction Successful</p>
                        <a href={"https://etherscan.io/tx/" + this.props.riseMintData.transactionHash} target="_blank" >Hash: {this.props.riseMintData.transactionHash.substring(0, 6)}...{this.props.riseMintData.transactionHash.substring(this.props.riseMintData.transactionHash.length - 4)}</a>
                    </div>}
                    {this.props.riseMintData.txdetails === null &&<div data-processing>
                        <p><img src={loading} alt="Loading" /> Transaction Processing</p>
                        <a href={"https://etherscan.io/tx/" + this.props.riseMintData.transactionHash} target="_blank" >Hash: {this.props.riseMintData.transactionHash.substring(0, 6)}...{this.props.riseMintData.transactionHash.substring(this.props.riseMintData.transactionHash.length - 4)}</a>
                    </div>}
                </div>}
                {this.props.riseMintData.err && this.props.riseMintData.err.error && this.props.riseMintData.err.message && <p style={{ 'marginTop': '10px' }} className="contact-us__message"> {this.props.riseMintData.err.error.message}</p>}
            </div>

        }

    }
}


export default Rise