import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
function Photo(props) {
    const post = props.post
    const loc = props.location
    const showbutton = (loc.pathname.indexOf('single') >= 0);


    return <span>
        <Link to={`/single/${post.id}`}> <span className="select-ape__option" ><img src={post.link} alt={post.description} width="243"
            height="243" /></span></Link>
    </span>





}


export default Photo
