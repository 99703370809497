
import React, {Component, useEffect, useState } from 'react'
import parse from 'html-react-parser';

class AccordionItem extends Component {

  constructor(props) {
    super(props);
    this.state={"show" :'close'};
  }
   
  toggeleShowState(){
       
    if(this.state.show=='open')
    this.setState({"show" :'close'})
    else

this.setState({"show" :'open'})
  }

    render () {
    
      
      return  ( 
        <div
          {...{
            className: `faq__accordion ${this.state.show} `, 
            onClick: () => { this.toggeleShowState() }
          }}
        >
          <div className="faq__accordion-btn">
           <p>
              {this.props.title}
            </p>
            <span className="plus">+</span>
          <span className="minus">-</span>
          </div>

          <div className="faq__accordion-content">
         <p> {parse(this.props.paragraph)}</p>

      </div>

           
        </div>
        )
      
    
  }

}


  export default AccordionItem;

 
