import React , { Component } from 'react';
import parse from 'html-react-parser';
import {Link} from 'react-router-dom';
import iconTwitter from '../img/Twitter-logo.svg';
import iconBubble from '../img/bubble.svg';
import arrow from '../img/arrow.svg';
import check from '../img/check.svg';
import { initializeApp } from "firebase/app";
import { ref , uploadBytesResumable , getDownloadURL , getStorage } from "firebase/storage";
import { set , ref as dbRef , getDatabase } from "firebase/database";
import { LocomotiveScrollProvider, useLocomotiveScroll } from 'react-locomotive-scroll';




/**
*
* Success 
*   - The Success Component
* 
*   Props
*       n/a
*
**/
const Success = (props) => {

    const {scroll}  = useLocomotiveScroll();

    React.useEffect(() => {
        if( scroll ){

        }
    }, [ scroll ]);


    scroll.scrollTo(0,0);
    
    return (
        <div className="submit" id="success">
            <h1>Success! We have received your story, thank you for your submission!</h1>
        </div>
    )
}


class Submit extends Component {


    /**
    *
    *   constructor
    *       - The Initialization Function
    *
    *   Params
    *       props:      (Object) The Properties passed to the component
    *
    **/
    constructor(props) {
        super(props);

        this.state = {
            id:         '',
            handle:     '',
            collection: null,
            consent:    false,
            story:      '',
            download:   false,
            percent:    0,
            open:       false,
            submitted:  false
        };

        this.limit = 1500;

        this.file = React.createRef();

        this.app = initializeApp({
            apiKey:             'AIzaSyDNEqEQ7vk5DrD_BlxR0hjq4HwS8HCtNRk',
            authDomain:         'foreverapes-test.firebaseapp.com',
            projectId:          'foreverapes-test',
            storageBucket:      'foreverapes-test.appspot.com',
            messagingSenderId:  '865656324488',
            appId:              '1:865656324488:web:f12941668a6e92e6fafa3d',
            measurementId:      'G-SK3N48WRE2'
        });

        this.storage = getStorage( this.app );

        this.handleClick = this.handleClick.bind(this);

    }


    componentDidMount(){
        document.addEventListener('mousedown', this.handleClick);
    }



    componentWillUnmount(){
        document.addEventListener('mousedown', this.handleClick);
    }




    handleClick(e){

        if( !document.querySelector('[data-dropdown]').contains( e.target ) )
            this.setState({
                open: false
            });
    }

    /**
    *
    *   handle
    *       - Set the Handle State
    * 
    *   Params
    *       n/a
    * 
    **/
    handle = (e) => {
        this.setState({
            handle: e.target.value
        });
    }

    /**
    *
    *   handle
    *       - Set the Handle State
    *
    *   Params
    *       n/a
    *
    **/
    collection = (e,value) => {

        if( e.target.tagName.toLowerCase() == 'span' )
            e.target = e.target.parentNode;

        if( e.target.getAttribute('disabled') === null ){

            var obj = this;

            obj.setState({
                collection: value,
                open:       false
            });
        }

    }

    /**
    *
    *   handle
    *       - Set the Handle State
    *
    *   Params
    *       n/a
    *
    **/
    id = (e) => {
        this.setState({
            id: e.target.value
        });
    }

    /**
    *
    *   Story
    *       - Set the Story State
    *
    *   Params
    *       n/a
    *
    **/
    story = (e) => {

        //Create Limit
        if( e.target.value.length > this.limit )
            return;

        //
        this.setState({
            story: e.target.value
        });

    }



    /**
    *
    *   Story
    *       - Set the Story State
    *
    *   Params
    *       n/a
    *
    **/
    open = (e) => {

        //
        this.setState({
            open: !this.state.open
        });

    }



    /**
    *
    *   file
    *       - Set the File State
    * 
    *   Params
    *       n/a
    * 
    **/
    upload = (e) => {

        var obj = this;

        obj.setState({
            file: this.file.current.files[0].name
        });

        //Prepare the Upload
        let storageRef  = ref( this.storage , `/files/${ this.file.current.files[0].name }`),
            uploadTask  = uploadBytesResumable( storageRef , this.file.current.files[0] );

        //Start the Upload
        uploadTask.on(
            'state_changed',
            ( snapshot ) => {

                const percent = Math.round(
                    ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100
                );

                //Update the State
                obj.setState({
                    'percent': percent 
                });

            },
            ( err ) => console.log(err),
            () => {
                getDownloadURL( uploadTask.snapshot.ref ).then((url) => {
                    
                    //
                    obj.setState({
                        download: url 
                    });

                })
            }
        );

    }



    /**
    *
    *   submit
    *       - Submit the Form
    * 
    *   Params
    *       n/a
    * 
    **/
    submit = (e) => {

        e.preventDefault();

        let db          = getDatabase( this.app ),
            reference   = dbRef( db , `stories/${ this.state.collection }/${ this.state.id }`);

        var obj = this;

        set( reference , {
            collection: this.state.collection,
            id:         this.state.id,
            handle:     this.state.handle,
            story:      this.state.story,
            consent:    this.state.consent 
        }).then(() => {

            //Update
            obj.setState({
                submitted: true
            });

        }).catch((e) => {
            console.log(e);
        });



    }








    /**
    *
    *   form
    *       - The Submittable Form Page
    * 
    *   Params
    *       n/a
    * 
    **/
    form = () => {

        //Are we ready to submit the form
        let ready = () => {
            return (
                this.state.collection
                &&
                this.state.story != ''
                &&
                this.state.id != ''
                &&
                this.state.handle != ''
                &&
                this.state.consent
            );
        };

        //The Button
        let button = () => {
            if( this.state.percent > 0 && this.state.percent < 100 ){
                return (
                    <>
                        Uploading
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ margin:'auto', background:'none', display:'block', shapeRendering:'auto', position: 'relative', top:'-20px', right:'-20px', transform:'scale(1.5)' }} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="50" cy="50" fill="none" stroke="#000" strokeWidth="3" r="6" strokeDasharray="28.274333882308138 11.42477796076938">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                            </circle>
                        </svg>
                    </>
                );
            }else{
                return (
                    <>
                        Submit
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22.7 18.1" style={{ 'enable-background': 'new 0 0 22.7 18.1' }} xmlSpace="preserve">
                            <path d="M22.7,9C22.7,9,22.7,9,22.7,9c0-0.4-0.2-0.8-0.4-1l-7.5-7.5c-0.6-0.6-1.5-0.6-2.1,0s-0.6,1.5,0,2.1l5.1,5.1H1.5
                                C0.7,7.6,0,8.3,0,9.1s0.7,1.5,1.5,1.5h16.1l-5,5c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l7.5-7.5
                            C22.6,9.9,22.7,9.5,22.7,9C22.7,9.1,22.7,9,22.7,9z"/>
                        </svg>
                    </>
                );
            }
        }


        //The Character Limit
        let limit = () => {
            return ( this.limit - this.state.story.length );
        };


        //The Collection
        let collection = () => {
            switch( this.state.collection ){
                case 'bayc':
                    return 'Ape';
                case 'mayc':
                    return 'Mutant';
                case 'punk':
                    return 'Punk';
                case 'meebit':
                    return 'Meebs';
                default:
                    return 'Select your collection';
            }
        };

        //update the consent
        let toggleConsent = (name,event) => {
            console.log(name,event)
            this.setState({
                consent: !this.state.consent
            });
        }


        let classes = () => {
            let state = 'select';

            if( this.state.open )
                state += ' open';

            return state;
        }

        return (
            <div className="submit">
                <h1>
                    Hello! <br />
                    this is my
                    <div data-dropdown className={ classes() }>
                        <div className="dropdown">
                            <div className="selected" onClick={ this.open }>
                                <span>{ collection() }</span>
                                <img src={ arrow } />
                            </div>
                            <ul className="dropdown">
                                <li onClick={(e) => this.collection(e,'bayc')}>
                                    Ape <span>BAYC</span>
                                </li>
                                <li onClick={(e) => this.collection(e,'mayc')}>
                                    Mutant<span>MAYC</span>
                                </li>
                                <li onClick={(e) => this.collection(e,'punk')}>
                                    Punk <span>CRYPTOPUNKS</span>
                                </li>
                                <li onClick={(e) => this.collection(e,'meebit')}>
                                    Meebs <span>MEEBITS</span>
                                </li>
                            </ul>
                        </div>
                        <select name="">
                            <option value="">Select your collection</option>
                            <option value="bayc">Ape</option>
                            <option value="mayc">Mutant</option>
                            <option value="cryptopunks">Punk</option>
                            <option value="meebits">Meeb</option>
                        </select>
                    </div><span>*</span> <br />
                    &amp; here is my story.
                </h1>
                <form>
                    <div className="story">
                        <textarea name="story" placeholder="Click here to begin telling your story..." onKeyUp={ this.length } onChange={ this.story } value={ this.state.story }></textarea>
                        <p className="limit">Characters remaining: <span>{ limit() }</span></p>
                    </div>
                    <div className="rows">
                        <h2>Few more things!</h2>
                        <div className="row">
                            <div className="field">
                                <img src={iconTwitter} />
                                <div className="textField at">
                                    <input type="text" name="handle" placeholder="Enter your Twitter handle here..." value={ this.state.handle } onChange={ this.handle } />
                                </div>
                                <sup>*</sup>
                            </div>
                            <div className="field">
                                <img src={iconBubble} />
                                <div className="textField hashtag">
                                    <input type="text" name="id" placeholder="Enter your NFT ID here..." value={ this.state.id } onChange={ this.id } />
                                </div>
                                <sup>*</sup>
                            </div>
                        </div>
                        <div className="row btnSubmit">
                            <div className="field consent">
                                <input type="checkbox" name="consent" id="consent" value="" defaultChecked={ this.state.consent } onChange={ toggleConsent } />
                                <label htmlFor="consent">
                                    <img src={ check } />
                                </label>
                                <p>
                                    I consent to the use of my story in the Forever Apes project*
                                </p>
                            </div>
                            <div>
                                <button name="submit" className="btn-green" disabled={ !ready() } onClick={ this.submit }>{
                                    button()
                                }</button>
                                No web3 connect needed to submit
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }




    
    /**
    *
    *   render
    *       - Render the Submit Function
    * 
    *   Params
    *       n/a
    * 
    **/
    render(){

        return (
            <>
                <nav className="navigation">
                    <div className="navigation__container">            

                        {/* <!-- Logo --> */}
                        <div className="navigation__logo">
                            <a className="navigation__logo-link" href="/">
                                FOREVER APES
                            </a>                
                        </div>
                        {/* <!-- END Logo --> */}

                      
                        <span {...{className:'btn-open is-inview', 'data-animate':"fadeIn,moveUp", style: { 'animationDelay': '4.50s' },  onClick: () => {openNav() }}}>
                            <svg width="36" height="36" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L17 1" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
                                <path d="M1 6L17 6" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
                                <path d="M1 11H17" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
                            </svg>                    
                        </span>

                        {/* <!-- Mobile menu --> */} 
                        <div id="mySidenav" className="sidenav">                        
                            <ul className="sidenav__ul-btn">
                                <li>
                                    <div className="btn-green">Story Portal</div>
                                </li>
                            </ul>                                   
                        </div>   
                        {/* <!-- END Mobile menu --> */} 

                    </div> 
                </nav>
                {  this.state.submitted ? <Success /> : this.form() }
            </>
        );
    }
    
}


export default Submit;