import React, { Component } from 'react'


import Form from './Form'


class Single extends Component {
    render() {


        
        const { match, appMainStateData } = this.props
        const id = match.params.id

        const post = appMainStateData.posts.find((post) => {

            if (post.id === id)
                return post;

        })
        // console.log(this.props.appMainStateData)
        // console.log(post)

        let walletNotConnectedORNoApes = false;
        let showApe=false;
        let message = "";
        if (this.props.appMainStateData.walletConnection === null) {
            console.log("loading");
            const loading = true;
        }
        if (this.props.appMainStateData.walletConnection == false) {
            // console.log("Wallet not connected");
             walletNotConnectedORNoApes = true;
            message = "Please connect your wallet to select an ape.";
        }
        else if (this.props.appMainStateData.apesFetched == true && this.props.appMainStateData.walletConnection == true && this.props.appMainStateData.posts.length == 0) {
            // console.log("Wallet has no Apes");
             walletNotConnectedORNoApes = true;
            message = "The connected wallet doesn't own any apes. Only Ape owners can submit a story"

        }
        else if (this.props.appMainStateData.accept_submission &&this.props.appMainStateData.accept_submission == false&&this.props.appMainStateData.show_count==true) {
            console.log("Maximum story submissions reached");
             walletNotConnectedORNoApes = true;
            message = "All 150 stories have been submitted. Stay tuned to our roadmap to follow along next steps!"
    
        }
        else if (this.props.appMainStateData.fetchStoryCountWalletFlag==true&&this.props.appMainStateData.walletStoryCount.count >=1&&this.props.appMainStateData.walletStoryCount.postId!=id ) {
           // console.log("Maximum story submissions reached per wallet. You have already submitted your story for Ape#"+post.id);
             walletNotConnectedORNoApes = true;
            message = "Only one story is allowed per wallet. You have already submitted your story for Ape#"+this.props.appMainStateData.walletStoryCount.postId;
            
        }
        

        if (walletNotConnectedORNoApes) {
            return <div>
                <section className="contact-us">

                    <div className="container-np">
                        <div className="contact-us__wrp">

                            <h1 className="contact-us__title"></h1>


                            <h1 className="contact-us__text-top">  {message}</h1>


                        </div>
                    </div>
                </section>
            </div>
        }


        const index = this.props.appMainStateData.posts.findIndex((post) => post.id === id)

     
        if (this.props.loading === true) {

            return <section className="contact-us">
                <div className="loader"> ...loading </div>
            </section>
        } else if (post&&this.props.appMainStateData.fetchStoryCountWalletFlag&&this.props.appMainStateData.show_count>0) {
            return <div >
                <section className="contact-us">

                    <div>
                        <Form post={post} {...this.props} index={index} />

                    </div>
                </section>
            </div>
        } else {

            return <div ><section className="contact-us">
                <div className="container-np">
                    <div className="contact-us__wrp">


                    </div>

                </div>
            </section>
            </div>
        }
    
    }
}

export default Single