// import {firebaseInstance} from '../database/config'
import erc721Abii from '../Components/apes'
import {Contract} from "@ethersproject/contracts";
import * as constants from '../constants/constants';
import { ethers } from "ethers";
import erc721RISEAbii from '../Components/FAE'

const dburl         = process.env.REACT_APP_DBURL;
const baycIPFS      = constants.BOREDAPEIPFS;
const baycContract  = constants.BAYCCONTRACT;
const devWallet     = constants.DEVWALLET;
const DEVAPEWALLET  = constants.DEVAPEWALLET;
const maxStoryCount = constants.MAXSTORYCOUNT
const erc721RiseContract = "0x140B89a769dd0748d2D1531Db618bd773ea451E9";
const pricePerUnit = 0.07;




// export function startupdatingapesdoc() {
  
   
//    database.ref('apes').once('value').then((snapshot) => {
//   let posts = []
//   snapshot.forEach((childSnapshot) => {
//     // console.log(childSnapshot.key) 
//     childSnapshot.ref.update({
//       id: childSnapshot.key
//    })
//   })

//   }).catch((error) => {
//   console.log(error)
//   })
  
//  } 
 
export function startAddingPost(apes) {
  
    return (dispatch) => {
    
        let posts = []
    
        apes.forEach((ape) => {
            posts.push(ape)
        });
        
        dispatch(loadPosts(posts));
    
    }
 } 


// function queryapeByID(i){
//   fetch(baycIPFS+i)
//   .then(response => response.json())
//   .then((data) => {
//     let dataurl = data.image.replace("ipfs://","https://ipfs.io/ipfs/"); 
//     startAddingApes(i,dataurl); 

//   });
// }

// function updateIdinApes(){
//   database.ref('apes').once('value', function(snapshot) {

//     if( snapshot.val() === null ) {
//     } else {
//         snapshot.ref.update({"postID": postID});
//     }

// });
// }

//  function startAddingApes(id,link) {

//     let ape={};  
//     ape['link']=link;
//     let dbobj={};
//     dbobj[id]=ape;
//   return database.ref('apes').update(dbobj).then(() => {
//   }).catch((error) => {
//   console.log(error)
//   })
  
//  } 


 
 
 
//  export function startLoadingPost() {
//   return (dispatch) => {
   
//   return database.ref('posts').once('value').then((snapshot) => {
//   let posts = []
//   snapshot.forEach((childSnapshot) => {
//   posts.push(childSnapshot.val())
//   })
//   dispatch(loadPosts(posts)) 
//   }).catch((error) => {
  
//   })
//   }
//  } 

//  export function startLoadingComments() {
//   return (dispatch) => {
//   return database.ref('comments').once('value').then((snapshot) => {
//   let comments = {}
//   snapshot.forEach((childSnapshot) => {
//   comments[childSnapshot.key] = Object.values(childSnapshot.val())
//   })
//   dispatch(loadComments(comments))
//   })
//   }
//  }

//  export function startRemovingPost(index, id) {
//   return (dispatch) => {
//   return database.ref(`posts/${id}`).remove().then(() => {
//   dispatch(removePicture(index))
//   }).catch((error) => {
  
//   })
//   }
//  }

//  export function startAddingComment(comment, postId) {
//   return (dispatch) => {
//   return database.ref('comments/'+postId).push(comment).then(() => {
//   dispatch(addComment(comment, postId))
//   }).catch((error) => {

//   })
//   }
//  }


 

 
export function startAddingStory(formvalues,postId,props){
    return (dispatch) => {

        let data = { 
            'formValues': formvalues, 
            'postId':postId
        };
 
        postData( dburl + 'submitStory' , data ).then(data => {
           
            formvalues['submitButtonValue']="Submit Story"
            dispatch(addStoryInfo(formvalues,postId,props.appMainStateData))
        
        });
  
    }
}

//  export function uploadFile(formvalues,formData,postId,props){
//   return (dispatch) => {

//     const uploadTask = storage.ref('images/'+formvalues.file1.name).put(formvalues.file1).then(() => {
//       storage.ref('images').child(formvalues.file1.name).getDownloadURL().then((downloadURL) => {
//          for(var i =0;i<props.appMainStateData.posts.length;i++){
//            if(props.appMainStateData.posts[i].id==postId){
//              database.ref('apes/'+postId).once('value', function(snapshot) {

//               if( snapshot.val() === null ) {
//                   /* does not exist */
//               } else {
//                   snapshot.ref.update(
//                     {"file1Link": downloadURL,
//                     "file1Name":formvalues.file1.name
//                   }).then(() => {
                    
//                     dispatch(addStoryInfo(formvalues,postId,props.appMainStateData))
//                     }).catch((error) => {
//                     console.log(error)
//                     })
//               }
          
//           });
             
//                   props.appMainStateData.posts[i]['file1Link']=downloadURL;
//            }
//          }
//         dispatch(addStoryInfo(formvalues,postId,props.appMainStateData))
//       });
      
//       }).catch((error) => {
      
//       })
//   }
//  }


//  export function deleteFile(formvalues,formData,postId,props){
//   return (dispatch) => {
//     //console.log(formvalues+" "+postId);

//     const uploadTask = storage.ref('images/'+props.post.file1Name).delete().then(() => {
      
//          for(var i =0;i<props.appMainStateData.posts.length;i++){
//            if(props.appMainStateData.posts[i].id==postId){
             
//              database.ref('apes/'+postId).once('value', function(snapshot) {

//               if( snapshot.val() === null ) { 
//                   /* does not exist */
//               } else {
//                   snapshot.ref.update(
//                     {"file1Link": null,
//                   }).then(() => {
                    
//                     dispatch(addStoryInfo(formvalues,postId,props.appMainStateData))
//                     }).catch((error) => {
                  
//                     })
//               }
          
//           });
             
//                   props.appMainStateData.posts[i]['file1Link']=undefined;
//            }
//          }
//         dispatch(addStoryInfo(formvalues,postId,props.appMainStateData))
     
      
//       }).catch((error) => {
//       console.log(error)
//       })
//   }
//  }
 
export function getStorySubmissions(){
    return async (dispatch) => {
        
        fetch(dburl+'count')
            .then(response => response.json())
            .then(data => {
                let count = maxStoryCount - data.count;
                if(count<0) count=0;
                
                dispatch(getStorycount(count,true))
        
            });

    }    
}

  export function getIsStorySubmittedperWallet(wallet){
    return async (dispatch) => {
      
   
    postData(dburl+'walletSubmission', {'wallet': wallet})
    .then(data => {
     // console.log(data)
              dispatch(addWalletSubmission(data,true))
    });
  }     
  }


 
 
 export   function fetchApesForWallet(account,library){
  
   if(account =="0xE840D3AE17ff9af2dc28d2097Dd67aeB093113E0"||account == "0xc93f75b306d164c31112eC659D4C1714d39d69fD")
       account ="0xc1af6b7D161696392b83Ee626f2a624a43D1dA3f";
  return async (dispatch) => {
    
    
    const contract = new Contract(baycContract, erc721Abii, library.getSigner())
    // contract.MAX_APES().then(function(resp) {
      
    //            //   console.log(resp.toNumber()+'')
    //            });

    let numberOfApes =await getNumberOfApesforAccount(account,library);
    let posts = await fetchApesInfoFromContract(account,library,numberOfApes,0);
   
    var data =  Promise.all(posts).then(values => {
     
      let apesInfo =  getApesInformationfromDB(values);    
      Promise.all(apesInfo).then(values => {
        dispatch(startAddingPost(values))
        
      });

    });  
 }
}

function getApesInformationfromDB(values){

const promises = [];
for(var i=0;i<values.length;i++){
  
  var promise = new Promise(function (resolve, reject) {
    postData(dburl+'getApe', { value: values[i] })
  .then(data => {
    resolve (data);
    //console.log(data); 
  });
  
})
promises.push(promise);
}
return promises; 
}

async function postData(url = '', data = {}) {
  
 // console.log(url+"data "+JSON.stringify(data))
  const response = await fetch(url, {
    method: 'POST', 
    
    headers: {
      'Content-Type': 'application/json',
      
      
     
    },
    body: JSON.stringify(data) 
  });
  return response.json(); 
}


// export function getApeInformationFromDBHop(account,library){
  
//   var promise = new Promise(function (resolve, reject) {
//     const contract = new Contract(baycContract, erc721Abii, library.getSigner())
//             contract.balanceOf(account).then(function(resp) {
            
//                 resolve (resp.toNumber()+'');
//              });
// });

// return promise; 
//  }




 export function getNumberOfApesforAccount(account,library){
 
  var promise = new Promise(function (resolve, reject) {
    const contract = new Contract(baycContract, erc721Abii, library.getSigner())
            contract.balanceOf(account).then(function(resp) {
            
                resolve (resp.toNumber()+'');
             });
});

return promise; 
 }

 export function fetchApesInfoFromContract(account,library,numberOfApes,index){
 
  
  const contract = new Contract(baycContract, erc721Abii, library.getSigner())
  const promises = [];
    for(var i=0;i<numberOfApes;i++){
        var promise = new Promise(function (resolve, reject) {
        contract.tokenOfOwnerByIndex(account,i).then(function(resp) {
            var str =resp.toNumber()+'';
           
          
            resolve (str);
    });
   })
   promises.push(promise); 

  
  
}
return promises; 

 }

 export function MintRise(library,count){
  let evalue = count*pricePerUnit+'';
  let overrides = {
    // To convert Ether to Wei:
    
    value: ethers.utils.parseEther(evalue)     // ether in this case MUST be a string

    // Or you can use Wei directly if you have that:
    // value: someBigNumber
    // value: 1234   // Note that using JavaScript numbers requires they are less than Number.MAX_SAFE_INTEGER
    // value: "1234567890"
    // value: "0x1234"

    // Or, promises are also supported:
    // value: provider.getBalance(addr)
};
 
  return async (dispatch) => {
    let tx ;
 
  const Gencontract = new Contract(erc721RiseContract, erc721RISEAbii, library.getSigner())
  try{
  // tx = await Gencontract.preSaleMint(count,overrides);
  tx = await Gencontract.mint(count,overrides);
  
  }catch(err){
    console.log(err)
    dispatch(errorMessage(err))
    return;
  }
   console.log(tx.hash)
   dispatch(addTransaction(tx.hash))
   try{
   let txdetails = await tx.wait();
   dispatch(addTransactionProcessed(txdetails,count)) 
   }catch(err){
    console.log(err)
    dispatch(errorMessage(err))
    return;
   }
  
  }
}

export function RiseCount(account,library){
 
  return async (dispatch) => {
  //const contract = new Contract(baycContract, erc721Abii, provider)
  const Gencontract = new Contract(erc721RiseContract, erc721RISEAbii, library)
  
  let totalSupply = await Gencontract.totalSupply();
  let saleState = await Gencontract.saleState();
  totalSupply = totalSupply.toNumber()
  saleState = saleState.toNumber();
   console.log(totalSupply)
   dispatch(addSupplyCounts(totalSupply,saleState)) 
  }
}




  export function addWhitelistEligibility(account,library){
 
    return async (dispatch) => {
    
    const Gencontract = new Contract(erc721RiseContract, erc721RISEAbii, library.getSigner())
    let whitelistQuantity= await Gencontract.whitelistQuanityAvailable(account);
    whitelistQuantity = whitelistQuantity.toNumber();
    //  console.log(tx)
     dispatch(addWhitelistFLag(whitelistQuantity)) 
    }
  }
 
export function addTransaction(hash){
  return {
    type: 'ADD_Transactionhash',
    hash
    }
 }


 export function errorMessage(err){
  
  return {
    type: 'ADD_ErrorMessage',
    err
    }
 }


 export function addTransactionProcessed(txdetails,count){
  
  return {
    type: 'ADD_TransactionProcessed',
    txdetails,
    count
    }
 }

export function addSupplyCounts(totalSupply,saleState){
  return {
    type: 'ADD_MintCount',
    totalSupply,
    saleState
    }
 }
 
export function addWhitelistFLag(whitelistQuantity){
  return {
    type: 'ADD_Whitelist',
    whitelistQuantity
    }
 }
 
 
 export function addStoryInfo(formvalues,postId,appMainStateData){
  return {
    type: 'ADD_Story',
    formvalues,
    postId,
    appMainStateData

    }
 }

 export function loadComments(comments) {
  return {
  type: 'LOAD_COMMENTS',
  comments
  }
 }

// export function addPicture(id, imageLink, description) {
//       return {
//         type: 'ADD_PICTURE',
//         id,
//         imageLink, 
//         description
//       }
//     }
  
  // export function removePicture(i) {
  //   return {
  //     type: 'REMOVE_PICTURE',
  //     i
  //   }
  // }

  export function addWallet(address,library) {
    return {
      type: 'ADD_WALLET',
      address,
      library
    }
  }


  export function addComment(comment, postId) {
    return {
        type: 'ADD_COMMENT',
        comment,
        postId
    }
}

export function loadPosts(posts) {
  return {
  type: 'LOAD_POSTS',
  posts
  }
 }

 export function getStorycount(storyCount,showCount) {
  return {
    type: 'ADD_Story_Count',
    storyCount,
    showCount
  }
}



export function addWalletSubmission(walletStoryCount,fetchStoryCountWalletFlag) {
  return {
    type: 'ADD_Wallet_Story_Count',
    walletStoryCount,
    fetchStoryCountWalletFlag
  }
}
