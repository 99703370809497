import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'

const POLLING_INTERVAL = 12000;
//const RPC_URLS = ['https://mainnet.infura.io/v3/3d0c0fc48dfd4c4e93fad45765fa236a'];
const API_KEY=process.env.REACT_APP_INFURA_API_KEY;
const infuraurl = "https://mainnet.infura.io/v3/"+API_KEY;

const RPC_URLS = [infuraurl];

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42],
})

export const network = new NetworkConnector({
  urls: { 1: RPC_URLS[0] },
  defaultChainId: 1
})